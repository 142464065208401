import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import config from "./config";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const Navigate = useNavigate();

  useEffect(() => {
    payload();
  }, []);

  const payload = async () => {
    try {
      const res = await axios.get(
        config.apipath + "admin/token",
        config.headers()
      );
      if (res.data.payload.role === 0) {
        Swal.fire({
          icon: "error",
          title: "ไม่มีสิทธ์เข้าใช้งาน",
          showConfirmButton: false,
          timer: 1500,
        });
      } else if (res.data.payload.role === 1) {
        Navigate("/listcategory");
      } else if (res.data.payload.role === 2) {
        Navigate("/owner");
      }
    } catch (error) {}
  };

  const login = async () => {
    try {
      if (Email) {
        if (Password) {
          const datauser = {
            email: Email,
            password: Password,
          };

          const login = await axios.post(
            config.apipath + "admin/login",
            datauser
          );
          if (login.data.token === undefined) {
            Swal.fire({
              icon: "info",
              title: login.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            const token = login.data.token.access_token;
            localStorage.setItem("Token", token);
            payload();
          }
        } else {
          Swal.fire({
            icon: "info",
            title: "กรุณากรอก Password",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        Swal.fire({
          icon: "info",
          title: "กรุณากรอก Email",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  return (
    <div className="relative">
      {/* Background Overlay */}
      <div className="fixed inset-0 bg-gray-900 bg-opacity-50 z-10 transition-opacity duration-300 opacity-100">
        {/* Modal Box */}
        <div className="fixed inset-0 flex items-center justify-center z-20 transition-opacity duration-300 opacity-100">
          <div
            className={`rounded-xl bg-gray-800 bg-opacity-50 px-16 py-10 shadow-lg backdrop-blur-md max-sm:px-8`}
          >
            <div className="text-white">
              <div className="mb-8 flex flex-col items-center">
                <img
                src="https://media.discordapp.net/attachments/1256092048931688498/1293117296696492042/BXOK-4.png?ex=672c7054&is=672b1ed4&hm=f4a3f3f0d837ded69fc34782dab17ce2cc2325aec02d831b639cd7a46f02f3a6&=&format=webp&quality=lossless&width=899&height=899"
                  // src="https://cdn.discordapp.com/attachments/1195436273758912572/1218254139532771428/image-removebg-preview_11.png?ex=6606fe94&is=65f48994&hm=cce307cc361bd0308a80e0f94c455b5ca57da18250d1a7cda93a5cd6de4d623b&"
                  width="150"
                  alt=""
                />
                <h1 className="mb-2 text-2xl">Login เข้าสู่ระบบ</h1>
                <span className="text-gray-300">Enter Login Details</span>
              </div>
              <div className="mb-4 text-lg">
                <input
                  className="rounded-3xl border-none bg-yellow-400 bg-opacity-50 px-6 py-2 text-center text-inherit placeholder-slate-200 shadow-lg outline-none backdrop-blur-md"
                  type="text"
                  name="name"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="mb-4 text-lg">
                <input
                  className="rounded-3xl border-none bg-yellow-400 bg-opacity-50 px-6 py-2 text-center text-inherit placeholder-slate-200 shadow-lg outline-none backdrop-blur-md"
                  type="Password"
                  name="name"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="mt-8 flex justify-center text-lg text-black">
                <button
                  onClick={login}
                  className="rounded-3xl bg-yellow-400 bg-opacity-50 px-10 py-2 text-white shadow-xl backdrop-blur-md transition-colors duration-300 hover:bg-yellow-600"
                >
                  Login
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
