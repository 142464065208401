import { useEffect, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import Swal from "sweetalert2";
import BarTablt from "./BarTable";
import BarEmployee from "../component/BarEmployee";
import ComponentToPrinttable from "../../Component/ComponentToPrinttable";

function Tablelist() {
  const Navigate = useNavigate();
  const [data, setdata] = useState([]);
  const [table, settable] = useState('');
  const [img, setimg] = useState('');
  const [showModal, setShowModal] = useState(false);
  const componentRef = useRef();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const res = await axios.get(config.apipath + "get/table");
    setdata(res.data);
  };

  const openModal = (table) => {
    if(table.Qrcodetable){
      table.Qrcodetable.map((Qrcodetable) => (
        setimg(Qrcodetable.img)
      ))
    }
    // setimg
    settable(table.table)
    setShowModal(!showModal);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <>
      <BarEmployee>
        <div className="mt-5 col-span-6 m-2 rounded-lg sm:grid-cols-8">
          <BarTablt count={data.length} />
          <div className="mt-5 h-96 rounded-lg">
            <div className="grid gap-x-2 gap-y-10 grid-cols-6 px-5 ml-2 p-0">
              {/* Open the modal using document.getElementById('ID').showModal() method */}

              {data.length > 0 ? (
                <>
                  {data.map((table, index) => (
                    <>
                      <div className="mt-10 col-auto">
                        <button
                          key={index}
                          className="btn btn-active"
                          onClick={(e) => openModal(table)}
                        >
                          ::: โต๊ะที่ {table.table} :::
                        </button>
                      </div>
                    </>
                  ))}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>

        <div
          id="authentication-modal"
          className={`${
            showModal ? "fixed" : "hidden"
          } overflow-y-auto overflow-x-hidden inset-0 z-50 flex justify-center items-center`}
        >
          <div className="absolute inset-0 bg-black opacity-50"></div>

          <div className="relative bg-white rounded-lg shadow-lg max-w-md w-full  p-4 ml-20">
            <div className="flex items-center justify-between pb-4">
              <button
                className="w-3/6 h-5/6 bg-green-300 rounded-lg p-1"
                onClick={handlePrint}
              >
                <i className="fa-solid fa-print "></i> พิมพ์บิล
              </button>
              <button
                type="button"
                className="w-3/6 h-5/6 bg-red-300 rounded-lg p-1 ml-3"
                onClick={openModal}
              >
                ปิด
              </button>
            </div>
            <div className="w-full">
              <ComponentToPrinttable  ref={componentRef} table={table} img={img}> </ComponentToPrinttable>
            </div>
          </div>
        </div>
      </BarEmployee>
    </>
  );
}

export default Tablelist;
