import Dashboard from "./dashboard";
import { useEffect, useState } from "react";
import axios from "axios";
import config from "../config";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
export default function DashbordBanner() {
  const [banner, setbanner] = useState([]);
  const [bannerid, setbannerid] = useState("");
  const [bannerfile, setbannerfile] = useState("");
  const [button, setbutton] = useState(false);
  const Navigate = useNavigate();
  useEffect(() => {
    payload();
    data();
  }, []);

  const payload = async () => {
    try {
      const res = await axios.get(
        config.apipath + "admin/token",
        config.headers()
      );
      if (res.data.payload === undefined) {
        Navigate("/");
      }
    } catch (error) {}
  };
  const data = async () => {
    try {
      const res = await axios.get(
        config.apipath + "owner/banner",
        config.headers()
      );
      setbanner(res.data);
    } catch (e) {}
  };

  const addbanner = async () => {
    try {
      if (bannerid !== "" && bannerfile !== undefined) {
        if (bannerid !== "") {
          if (bannerfile !== undefined) {
            const formData = new FormData();
            formData.append("id", bannerid);
            formData.append("file", bannerfile);
            const response = await axios.post(
              config.apipath + "owner/addbanner",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: "Bearer" + localStorage.getItem("Token"),
                },
              }
            );
            if (response.data.message === "success") {
              Swal.fire({
                title: "สำเร็จ",
                icon: "success",
              });
              data();
              setbannerfile();
              setbutton(!button);
            } else {
              Swal.fire({
                title: response.data.message,
                icon: "info",
              });
            }
          } else {
            Swal.fire({
              title: "กรุณาแนบรูป",
              icon: "info",
            });
          }
        } else {
          Swal.fire({
            title: "กรุณาเลือกลำดับรูป",
            icon: "info",
          });
        }
      } else {
        Swal.fire({
          title: "กรุณาเลือกลำดับรูป",
          icon: "info",
        });
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "error",
      });
    }
  };

  const del = async (item) => {
    try {
      if (item === "") {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      Swal.fire({
        title: "ยืนยันการลบรายการ",
        text: "กรณีลบรายการไปแล้วจะไม่สามารถเรียกคืนค่าได้อีก ต้องเพิ่มใหม่เท่านั้น",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const del = await axios.delete(
            config.apipath + "owner/delbanner/" + item.Number,
            config.headers()
          );

          if (del.data.message === "success") {
            Swal.fire({
              title: "ลบรายการสำเร็จ",
              text: "Your file has been deleted.",
              icon: "success",
            });
            data();
          } else {
            Swal.fire({
              icon: "error",
              title: "เกิดข้อผิดพลาด",
              showConfirmButton: false,

              timer: 1500,
            });
          }
        }
      });
    } catch {
      Swal.fire({
        icon: "error",
        title: "เกิดข้อผิดพลาด",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  
  return (
    <>
      <Dashboard>
        <div className=" col-span-12 text-xs sm:text-xs md:text-lg lg:text-lg">
          {" "}
          การจัดการรูป
        </div>
        <div className="col-span-12 sm:col-span-12 md:col-span-12 xl:col-span- text-xs sm:text-xs md:text-lg lg:text-lg">
          {button ? (
            <>
              {" "}
              <div className="animate-fadeIn">
                <div className="elative grid grid-cols-12 min-w-0 mt-5 mb-6 ml-5 break-words bg-gray-100 dark:bg-gray-950 shadow-soft-xl dark:shadow-soft-dark-xl rounded-2xl bg-clip-border col-span-12 sm:col-span-12 md:col-span-12 xl:col-span-12">
                  <div className="stats stats-vertical shadow col-span-12">
                    <div className="stat col-span-6 sm:col-span-12 md:col-span-12 lg:col-span-12  xl:col-span-4  text-center">
                      <select
                        onChange={(e) => setbannerid(e.target.value)}
                        value={bannerid}
                        className="select select-accent flex items-center gap-2 w-full "
                      >
                        <option >เลือกลำดับรูป</option>
                        {banner.length > 0 ? (
                          <>
                            {Array.from(
                              {
                                length:
                                  Math.max(
                                    ...banner.map((item) => item.Number)
                                  ) + 1,
                              },
                              (_, index) => {
                                const number = index + 1;
                                const isMissing = !banner.some(
                                  (item) => item.Number === number
                                );
                                return (
                                  isMissing && (
                                    <option key={index} value={number}>
                                      {number}
                                    </option>
                                  )
                                );
                              }
                            )}
                          </>
                        ):(<><option value="1">
                          1
                        </option></>)}
                      </select>
                    </div>
                    <div className="stat col-span-6 sm:col-span-12 md:col-span-12 lg:col-span-12  xl:col-span-4 text-center">
                      <input
                        onChange={(e) => setbannerfile(e.target.files[0])}
                        type="file"
                        className="file-input file-input-bordered file-input-accent flex items-center gap-2 w-full grow"
                      />
                    </div>

                    <div className="stat col-span-6 sm:col-span-12 md:col-span-12 lg:col-span-12  xl:col-span-4 text-center">
                      <button
                        onClick={addbanner}
                        className="btn btn-square btn-outline bg-accent flex items-center gap-2 w-full "
                      >
                        เพิ่มรูป
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          <div className="relative flex flex-col min-w-0 mt-5 mb-6 ml-5 break-words  dark:bg-gray-950 shadow-soft-xl dark:shadow-soft-dark-xl rounded-2xl bg-clip-border border-spacing-6 col-span-12">
            <div className="ml-1 col-span-10 ">
              {" "}
              <i className="fa-sharp fa-solid fa-chair mr-2"></i>
              รูป
              <button
                onClick={(e) => setbutton(!button)}
                className="btn btn-square btn-outline btn-accent col-span-1 w-1/6 h-auto m-5"
              >
                <i className="fa-sharp fa-solid fa-plus"></i>
              </button>
            </div>
            <div className="relative overflow-x-auto shadow-soft-xl dark:shadow-soft-dark-xl rounded-2xl bg-clip-border border-spacing-6">
              <table className="w-full text-sm text-left shadow rtl:text-right text-gray-500 dark:text-gray-400 ">
                <thead className="text-center text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3 rounded-s-lg bg-yellow-50">
                      ลำดับที่
                    </th>
                    <th scope="col" className="px-6 py-3 bg-orange-50">
                      รูป
                    </th>
                    <th scope="col" className="px-6 py-3 bg-red-50">
                      ตั้งค่า
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {banner.length > 0 ? (
                    <>
                      {" "}
                      {banner.map((banner, index) => (
                        <>
                          <tr className="bg-white dark:bg-gray-800 text-center">
                            <td
                              key={index}
                              className="px-6 py-4 bg-amber-50 text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {banner.Number}
                            </td>
                            <td
                              key={index}
                              className="px-6 py-4 bg-amber-50 text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              <img
                                className="mx-auto"
                                src={config.img + banner.Bannerimg}
                                style={{ maxWidth: "300px", height: "100px" }}
                              />
                            </td>
                            <td className="px-6 py-4 bg-red-50 ">
                              <div className="flex">
                                <button
                                  onClick={(e) => del(banner)}
                                  className="btn btn-circle btn-outline btn-error w-2/4 h-2/4 ml-5"
                                >
                                  <i className="fa-sharp fa-solid fa-trash"></i>
                                </button>
                              </div>
                            </td>
                          </tr>{" "}
                        </>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Dashboard>
    </>
  );
}
