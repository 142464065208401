import { useEffect, useState } from "react";
import BarEmployee from "../component/BarEmployee";
import Barcategory from "./barcategory";
import axios from "axios";
import config from "../../config";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function Addcategory() {
  const Navigate = useNavigate();
  const [categoryname, setcategoryname] = useState("");
  const [Job, setJob] = useState("");
  const [categoryfile, setcategoryfile] = useState("");
  const [datajob, setdatajob] = useState([]);

  useEffect(() => {
    payload();
    fetchData();
  }, []);
  const payload = async () => {
    try {
      const res = await axios.get(
        config.apipath + "admin/token",
        config.headers()
      );
      if (res.data.payload === undefined) {
        Navigate("/");
      }
    } catch (error) {}
  };
  const fetchData = async () => {
    const res = await axios.get(config.apipath + "admin/job", config.headers());
    setdatajob(res.data);
  };

  const handleFileChange = (event) => {
    setcategoryfile(event.target.files[0]);
  };

  const addproduct = async () => {
    try {
      if (categoryfile !== "" || Job !== "" || categoryname !== "") {
        if (categoryfile !== "") {
          if (Job !== "") {
            if (categoryname !== "") {
              const formData = new FormData();
              formData.append("category", categoryname);
              formData.append("Job", Job);
              formData.append("file", categoryfile);

              const response = await axios.post(
                config.apipath + "admin/addcategory",
                formData,
                {
                  headers: {
                    "Authorization": "Bearer" + localStorage.getItem("Token"),
                    "Content-Type": "multipart/form-data",
                  },
                },
                config.headers()
              );
              if (response.data.message === "success") {
                await Swal.fire({
                  icon: "success",
                  title: "เพิ่มหมวดหมู่สินค้าสำเร็จ",
                  showConfirmButton: false,
                  timer: 1500,
                });
                window.location.reload();
              } else {
                await Swal.fire({
                  icon: "success",
                  title: response.data.message,
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            } else {
              Swal.fire({
                icon: "info",
                title: "กรุณากรอกชื่อหมวดหมู่สินค้า",
                showConfirmButton: false,
                timer: 1500,
              });
            }
          } else {
            Swal.fire({
              icon: "info",
              title: "กรุณาเลือกหมวดหมู่",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } else {
          Swal.fire({
            icon: "info",
            title: "กรุณาแนบรูป",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        Swal.fire({
          icon: "info",
          title: "กรุณากรอกข้อมูล",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch {
      Swal.fire({
        icon: "error",
        title: "เกิดข้อผิดพลาด",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  return (
    <>
      <BarEmployee>
        <div className="mt-5 col-span-6 m-2 rounded-lg sm:grid-cols-8">
          <Barcategory />
          <div className="mt-5 shadow-40 border-radius-10 h-96 shadow-xl rounded-lg">
            <div className="mb-6 grid grid-cols-12">
              <input
                type="text"
                placeholder="ชื่อสินค้า"
                onChange={(e) => setcategoryname(e.target.value)}
                value={categoryname}
                className="input input-bordered w-5/6 max-w-xs bg-orange-50 text-bg-blue-900 ml-10 my-10 col-span-6"
              />
              <form className="col-span-6">
                <select
                  onChange={(e) => setJob(e.target.value)}
                  className="input input-bordered w-5/6 max-w-xs bg-orange-50 text-bg-blue-900 ml-10 my-10 col-span-6"
                >
                  <option value="0">เลือกหมวดหมู่สินค้า</option>
                  {datajob.length > 0 ? (
                    <>
                      {datajob.map((job, index) => (
                        <option key={index} value={job.id}>
                          {job.Jobname}
                        </option>
                      ))}
                    </>
                  ) : (
                    <option>เลือกหมวดหมู่สินค้า</option>
                  )}
                </select>
              </form>

              <input
                type="file"
                onChange={handleFileChange}
                className="file-input file-input-ghost  input-bordered w-full ml-10 max-w-xs bg-orange-50 input col-span-6"
              />
            </div>
            <div className="tooltip mx-96 " onClick={addproduct}>
              <button className="btn bg-yellow-100 hover:bg-yellow-400 text-slate-950 mt-10">
                เพิ่มสินค้า
              </button>
            </div>
          </div>
        </div>
      </BarEmployee>
    </>
  );
}

export default Addcategory;
