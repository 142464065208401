import { useNavigate } from "react-router-dom";
import config from "../../config";
import Swal from "sweetalert2";
import axios from "axios";
export default function BarTablt(props) {
  const Navigate = useNavigate();

  const addqrcode = async () => {
    try {
      const { value: valuetable } = await Swal.fire({
        title: "กรอกหมายเลขโต๊ะ",
        input: "text",
        inputLabel: "เลขโต๊ะ",
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return "กรุณากรอกเลขโต๊ะ";
          }
          const intValue = parseInt(value, 10);
      
          // Check if the value is a valid number
          if (isNaN(intValue)) {
            return "ใส่จำนวนที่เป็นตัวเลข";
          }
          // Optional: you can add additional checks if needed, e.g., positive number
          if (intValue <= 0) {
            return "จำนวนต้องมากกว่า 0";
          }
        },
      });
      const data = {
        table: valuetable,
        link: config.apipqrcode + valuetable,
      };
      const res = await axios.post(
        config.apipath + "admin/insertQrcodetable",
        data,
        config.headers()
      );
      if (res.data.message === "success") {
        Swal.fire({
          icon: "sucess",
          title: "เพิ่มโต๊ะสำเร็จ",
          showConfirmButton: false,
          timer: 1500,
        });
        window.location.href = "/tablelist";
      } else {
        await Swal.fire({
          icon: "error",
          title: res.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: e.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const deltable = async () => {
    try {
      const { value: valuetable } = await Swal.fire({
        title: "กรอกหมายเลขโต๊ะ ที่จะลบ",
        input: "text",
        inputLabel: "เลขโต๊ะ ที่จะลบ",
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return "กรุณากรอกเลขโต๊ะ ที่จะลบ";
          }
        },
      });
      Swal.fire({
        title: "ยืนยันการลบ",
        text: "กรณีลบไปแล้วจะไม่สามารถเรียกคืนค่าได้อีก ต้องเพิ่มใหม่เท่านั้น",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res = await axios.get(
            config.apipath + "get/tabledel/" + valuetable
          );

          if (res.data !== "") {
            const del = res.data.table;

            const resdel = await axios.delete(
              config.apipath + "admin/delQrcodetable/" + del
            );

            if (resdel.data.message === "success") {
              await Swal.fire({
                icon: "success",
                title: "ลบโต๊ะสำเร็จ",
                showConfirmButton: false,
                timer: 1500,
              });
              window.location.href = "/tablelist";
            } else {
              Swal.fire({
                icon: "error",
                title: "เกิดข้อผิดพลาด",
                showConfirmButton: false,
                timer: 1500,
              });
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "เกิดข้อผิดพลาด",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }
      });
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: e.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  return (
    <>
      <div className="grid gap-x-8 gap-y-4 grid-cols-5">
        <button
          onClick={(e) => Navigate("/tablelist")}
          className="bg-orange-100 px-4 py-2 col-span-1 rounded-md text-sm font-medium hover:scale-110 hover:bg-stone-400 duration-300"
        >
          โต๊ะอาหารทั้งหมด
        </button>
        <button
          onClick={addqrcode}
          className="bg-orange-100 px-4 py-2 col-span-1 rounded-md text-sm font-medium hover:scale-110 hover:bg-stone-400 duration-300"
        >
          เพิ่มโต๊ะอาหาร
        </button>
        <button
          onClick={deltable}
          className="bg-orange-100 px-4 py-2 col-span-1 rounded-md text-sm font-medium hover:scale-110 hover:bg-stone-400 duration-300"
        >
          ลบ...
        </button>
        <button className="bg-orange-100 px-4 py-2 col-span-2 rounded-md text-sm font-medium hover:scale-110 hover:bg-stone-400 duration-300">
          จำนวนโต๊ะทั้งหมด : {props.count}
        </button>
      </div>
    </>
  );
}
