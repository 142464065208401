import BarEmployee from "../component/BarEmployee";
import BarProduct from "./BarProduct";
import axios from "axios";
import config from "../../config";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
export default function Productadd() {
  const [Productname, setProductname] = useState("");
  const [category, setcategory] = useState([]);
  const [categoryid, setcategoryid] = useState("");
  const [Producfile, setProducfile] = useState("");
  const [productcount, setproductcount] = useState("");
  const [productprice, setproductprice] = useState("");
  const urlSearchParams = new URLSearchParams(window.location.search);
  const id = urlSearchParams.get("id");
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const res = await axios.get(config.apipath + "admin/category/" + id,config.headers());
    setcategory(res.data);
  };

  const handleFileChange = (event) => {
    setProducfile(event.target.files[0]);
  };
  const addproduct = async () => {
    try {
      if (
        Producfile !== "" ||
        Productname !== "" ||
        productcount !== "" ||
        productprice !== ""
      ) {
        if (Productname !== "") {
          if (productprice !== "") {
            if (productcount !== "") {
              if (Producfile !== "") {
                const formData = new FormData();
                formData.append("name", Productname);
                formData.append("count", productcount);
                formData.append("price", productprice);
                formData.append("categoryid", id);
                formData.append("file", Producfile);

                const response = await axios.post(
                  config.apipath + "admin/addproduct",
                  formData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                      "Authorization": "Bearer" + localStorage.getItem("Token"),
                    },
                  }
                );
                if (response.data.message === "success") {
                  await Swal.fire({
                    icon: "success",
                    title: "เพิ่มหมวดหมู่สินค้าสำเร็จ",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  window.location.reload();
                } else {
                  Swal.fire({
                    icon: "error",
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 1500,
                  });
                }
              } else {
                Swal.fire({
                  icon: "info",
                  title: "กรุณาแนบรูป",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            } else {
              Swal.fire({
                icon: "info",
                title: "กรุณากรอกจำนวนสินค้า",
                showConfirmButton: false,
                timer: 1500,
              });
            }
          } else {
            Swal.fire({
              icon: "info",
              title: "กรุณากรอกราคาสินค้า",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } else {
          Swal.fire({
            icon: "info",
            title: "กรุณากรอกชื่อสินค้า",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        Swal.fire({
          icon: "info",
          title: "กรุณากรอกข้อมูล",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch {
      Swal.fire({
        icon: "error",
        title: "เกิดข้อผิดพลาด",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  return (
    <>
      <BarEmployee>
        <div className="mt-5 col-span-6 m-2 rounded-lg sm:grid-cols-8">
          <BarProduct name={category.Categoryname} id={category.id} />
          <div className="mt-5 shadow-40 bg-white border-radius-10 h-96 shadow-xl rounded-lg">
            <div className="ml-10 ">หมวดหมู่ {category.Categoryname}</div>
            <div className="mb-6 grid grid-cols-12">
              <input
                type="text"
                placeholder="ชื่อสินค้า"
                onChange={(e) => setProductname(e.target.value)}
                className="input input-bordered w-5/6 max-w-xs bg-orange-50 text-bg-blue-900 ml-10 my-10 col-span-6"
              />
              <input
                type="text"
                placeholder="ราคาสินค้า"
                onChange={(e) => setproductprice(e.target.value)}
                className="input input-bordered w-5/6 max-w-xs bg-orange-50 text-bg-blue-900 ml-10 my-10 col-span-6"
              />
              <input
                type="text"
                placeholder="จำนวน"
                onChange={(e) => setproductcount(e.target.value)}
                className="input input-bordered w-5/6 max-w-xs bg-orange-50 text-bg-blue-900 ml-10 my-10 col-span-6"
              />
              {/* <form className="col-span-6">
                <select className="input input-bordered w-5/6 max-w-xs bg-orange-50 text-bg-blue-900 ml-10 my-10 col-span-6">
                  <option value="0">เลือกหมวดหมู่สินค้า</option>
                  {category.length > 0 ? (
                    <>
                      {category.map((category, index) => (
                        <>
                          <option value={category.id} key={index}> {category.Categoryname}</option>
                        </>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </select>
              </form> */}
              <input
                type="file"
                onChange={handleFileChange}
                className="file-input file-input-ghost  input-bordered w-5/6 ml-10 my-10  max-w-xs bg-orange-50 input col-span-6"
              />
              <button
                onClick={addproduct}
                className="btn bg-yellow-100 hover:bg-yellow-400 text-slate-950 w-5/6 max-w-xs ml-10 my-10 col-span-6"
              >
                เพิ่มสินค้า
              </button>
            </div>
          </div>
        </div>
      </BarEmployee>
    </>
  );
}
