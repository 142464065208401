const config = {
    apipath: "https://apipos.bxoks.online/",
    img: "https://apipos.bxoks.online/img/",
    apipqrcode: "https://pos.bxoks.online/table?id=",
    headers: () => {
        return{
            headers: {
                Authorization: 'Bearer' + localStorage.getItem('Token'),
            },
        };
    },
    
}

export default config;