import Dashboard from "./dashboard";
import { useEffect, useState } from "react";
import axios from "axios";
import config from "../config";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
export default function DashbordCategory() {
  const Navigate = useNavigate();
  const [Category, setCategory] = useState("");
  const [datajob, setdatajob] = useState("");
  const [button, setbutton] = useState(false);
  const [btn, setbtn] = useState("");
  const [Categoryid, setCategoryid] = useState("");
  const [Categoryname, setCategoryname] = useState("");
  const [Categoryimg, setCategoryimg] = useState("");
  const [Categoryfile, setCategoryfile] = useState("");
  const [job, setjob] = useState("");
  const [topCategory, settopCategory] = useState([]);
  useEffect(() => {
    payload();
    data();
  }, []);

  const payload = async () => {
    try {
      const res = await axios.get(
        config.apipath + "admin/token",
        config.headers()
      );
      if (res.data.payload === undefined) {
        Navigate("/");
      }
    } catch (error) {}
  };
  const data = async () => {
    try {
      const res = await axios.get(config.apipath + "owner/categorycountfive",config.headers());

      const res1 = await axios.get(
        config.apipath + "admin/job",
        config.headers()
      );
      setCategory(res.data);
      setdatajob(res1.data);
      // setcount(res.data.tablecount);
      // settopCategory(res.data.topTablesWithBillsaleCount);
    } catch (e) {}
  };

  const handleFileChange = (event) => {
    setCategoryfile(event.target.files[0]);
  };

  const addCategory = async () => {
    try {
      payload();
      if (Categoryfile !== "" || job !== "" || Categoryname !== "") {
        if (Categoryname !== "") {
          if (job !== "") {
            if (Categoryfile !== "") {
              const formData = new FormData();
              formData.append("category", Categoryname);
              formData.append("Job", job);
              formData.append("file", Categoryfile);

              const response = await axios.post(
                config.apipath + "admin/addcategory",
                formData,
                {
                  headers: {
                    "Authorization": "Bearer" + localStorage.getItem("Token"),
                    "Content-Type": "multipart/form-data",
                  },
                },
                config.headers(),
              );
              if (response.data.message === "success") {
                await Swal.fire({
                  icon: "success",
                  title: "เพิ่มหมวดหมู่สินค้าสำเร็จ",
                  showConfirmButton: false,
                  timer: 1500,
                });
                setCategoryname("");
                setCategoryfile("");
                setjob("");
                data();
                await setbutton(!button);
              } else {
                await Swal.fire({
                  icon: "success",
                  title: response.data.message,
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            } else {
              Swal.fire({
                icon: "info",
                title: "กรุณาแนบรูป",
                showConfirmButton: false,
                timer: 1500,
              });
            }
          } else {
            Swal.fire({
              icon: "info",
              title: "กรุณาเลือกเคาน์เตอร์",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } else {
          Swal.fire({
            icon: "info",
            title: "กรุณากรอกชื่อหมวดหมู่สินค้า",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        Swal.fire({
          icon: "info",
          title: "กรุณากรอกข้อมูล",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {}
  };

  const editCategory = async () => {
    try {
      payload();
      if (Categoryfile !== "" || job !== "" || Categoryname !== "") {
        if (Categoryname !== "") {
          if (job !== "") {
            const formData = new FormData();

            formData.append("id", Categoryid);
            formData.append("name", Categoryname);
            formData.append("job", job);
            formData.append("file", Categoryfile);

            const response = await axios.put(
              config.apipath + "admin/updatecategory",

              formData,
              {
                headers: {
                  Authorization: "Bearer" + localStorage.getItem("Token"),
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            if (response.data.message === "success") {
              await Swal.fire({
                icon: "success",
                title: "แก้ไขหมวดหมู่สินค้าสำเร็จ",
                showConfirmButton: false,
                timer: 1500,
              });
              setCategoryname("");
              setCategoryfile("");
              setjob("");
              data();
              await setbutton(!button);
            } else {
              await Swal.fire({
                icon: "success",
                title: response.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          } else {
            Swal.fire({
              icon: "info",
              title: "กรุณาเลือกเคาน์เตอร์",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } else {
          Swal.fire({
            icon: "info",
            title: "กรุณากรอกชื่อหมวดหมู่สินค้า",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        Swal.fire({
          icon: "info",
          title: "กรุณากรอกข้อมูล",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {}
  };

  const add = async () => {
    await setbutton(!button);
    setCategoryname("");
    setCategoryfile("");
    setjob("");
    setCategoryimg("");
    setbtn("add");
  };

  const edit = async (item) => {
    await setbutton(!button);
    setbtn("edit");
    setCategoryname(item.Categoryname);
    setCategoryimg(item.Categoryimg);
    setjob(item.JobId);
    setCategoryid(item.id);
  };

  const deltable = async (item) => {
    try {
      payload();
      if (item === "") {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      Swal.fire({
        title: "ยืนยันการลบรายการ",
        text: "กรณีลบรายการไปแล้วจะไม่สามารถเรียกคืนค่าได้อีก ต้องเพิ่มใหม่เท่านั้น",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const del = await axios.delete(
            config.apipath + "admin/deletecategory/" + item.id,
            config.headers()
          );
          data();
          if (del.data.message === "success") {
            Swal.fire({
              title: "ลบรายการสำเร็จ",
              text: "Your file has been deleted.",
              icon: "success",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "เกิดข้อผิดพลาด",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }
      });
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: e.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  return (
    <>
      <Dashboard>
        <div className=" col-span-12 text-xs sm:text-xs md:text-lg lg:text-lg">
          {" "}
          การจัดการหมวดหมู่สินค้า
        </div>
        <div className="col-span-12 sm:col-span-12 md:col-span-12 xl:col-span- text-xs sm:text-xs md:text-lg lg:text-lg">
          <div className="relative flex flex-col min-w-0 mt-5 mb-6 ml-5 break-words bg-gray-100 dark:bg-gray-950 shadow-soft-xl dark:shadow-soft-dark-xl rounded-2xl bg-clip-border col-span-12 sm:col-span-12 md:col-span-12 xl:col-span-12">
            <div className="stats stats-vertical lg:stats-horizontal shadow grid grid-cols-1">
              <div className="stat col-span-1 text-center">
                <div className="stat-title">จำนวนหมวดหมู่สินค้า</div>
                <div className="stat-value">{Category.length}</div>
              </div>
            </div>
          </div>

          {button ? (
            <div className="animate-fadeIn r">
              <div className="elative grid grid-cols-12 min-w-0 mt-5 mb-6 ml-5 break-words bg-gray-100 dark:bg-gray-950 shadow-soft-xl dark:shadow-soft-dark-xl rounded-2xl bg-clip-border col-span-12 sm:col-span-12 md:col-span-12 xl:col-span-12">
                <div className="stats stats-vertical lg:stats-horizontal shadow col-span-12 grid grid-cols-13">
                  <div className="stat col-span-3 sm:col-span-12 md:col-span-12 lg:col-span-12  xl:col-span-3 text-center">
                    <label className="input input-bordered input-accent flex items-center gap-2 w-full ">
                      ชื่อ
                      <input
                        type="text"
                        className="grow"
                        placeholder="หมวดหมู่สินค้า"
                        onChange={(e) => setCategoryname(e.target.value)}
                        value={Categoryname}
                      />
                    </label>
                  </div>

                  <div className="stat col-span-3 sm:col-span-12 md:col-span-12 lg:col-span-12 xl:col-span-3 text-center grow">
                    <select
                      onChange={(e) => setjob(e.target.value)}
                      value={job}
                      className="select select-accent flex items-center gap-2 w-full "
                    >
                      {job !== "" ? (
                        <>
                          {datajob.length > 0 ? (
                            <>
                              {datajob.map((item, index) => (
                                <>
                                  {item.id === job ? (
                                    <>
                                      {" "}
                                      <option key={index} value={item.id}>
                                        {item.Jobname}
                                      </option>
                                    </>
                                  ) : (
                                    <>
                                      <option key={index} value={item.id}>
                                        {item.Jobname}
                                      </option>
                                    </>
                                  )}
                                </>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <>
                          <option value="0">เลือกเคาน์เตอร์</option>

                          {datajob.length > 0 ? (
                            <>
                              {datajob.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.Jobname}
                                </option>
                              ))}
                            </>
                          ) : (
                            <option>เลือกเคาน์เตอร์</option>
                          )}
                        </>
                      )}
                    </select>
                  </div>
                  
                    {Categoryimg ? (
                      <>
                      <div className="stat col-span-3 sm:col-span-12 md:col-span-12 lg:col-span-12  xl:col-span-3 text-center">
                        <img
                          className="mx-auto"
                          src={config.img + Categoryimg}
                          style={{ maxWidth: "200px", height: "100px" }}
                        />
                      </div>
                      </>
                    ) : (
                      <></>
                    )}
                  
                  <div className="stat col-span-3 sm:col-span-12 md:col-span-12 lg:col-span-12  xl:col-span-3 text-center">
                    <input
                      onChange={handleFileChange}
                      type="file"
                      className="file-input file-input-bordered file-input-accent flex items-center gap-2 w-full grow"
                    />
                  </div>
                  <div className="stat col-span-3 sm:col-span-12 md:col-span-12 lg:col-span-6  xl:col-span-3 text-center">
                    {btn === "add" ? (
                      <button
                        onClick={addCategory}
                        className="btn btn-square btn-outline btn-accent flex items-center gap-2 w-full "
                      >
                        เพิ่มหมวดหมู่สินค้า
                      </button>
                    ) : (
                      <button
                        onClick={editCategory}
                        className="btn btn-square btn-outline btn-accent flex items-center gap-2 w-full "
                      >
                        แก้ไขหมวดหมู่สินค้า
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className="relative flex flex-col min-w-0 mt-5 mb-6 ml-5 break-words  dark:bg-gray-950 shadow-soft-xl dark:shadow-soft-dark-xl rounded-2xl bg-clip-border border-spacing-6 col-span-12">
            <div className="ml-1 col-span-10 ">
              {" "}
              <i className="fa-sharp fa-solid fa-chair mr-2"></i>
              หมวดหมู่สินค้าทั้งหมด
              <button
                onClick={add}
                className="btn btn-square btn-outline btn-accent col-span-1 w-1/6 h-auto m-5"
              >
                <i className="fa-sharp fa-solid fa-plus"></i>
              </button>
            </div>
            <div className="relative overflow-x-auto shadow-soft-xl dark:shadow-soft-dark-xl rounded-2xl bg-clip-border border-spacing-6">
              <table className="w-full text-sm text-left shadow rtl:text-right text-gray-500 dark:text-gray-400 ">
                <thead className="text-center text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3 rounded-s-lg bg-yellow-50">
                      ชื่อ
                    </th>
                    <th scope="col" className="px-6 py-3 rounded-s-lg bg-amber-50">
                      สินค้า
                    </th>
                    <th scope="col" className="px-6 py-3 bg-orange-50">
                      รูป
                    </th>
                    <th scope="col" className="px-6 py-3 bg-orange-50">
                      เคาน์เตอร์
                    </th>
                    <th scope="col" className="px-6 py-3 bg-red-50">
                      ตั้งค่า
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Category ? (
                    <>
                      {Category.map((category, index) => (
                        <>
                          <tr
                            key={index}
                            className="bg-white dark:bg-gray-800 text-center"
                          >
                            <th
                              scope="row"
                              className="px-6 py-4 bg-yellow-50 text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {category.Categoryname}
                            </th>
                            <td className="px-6 py-4 bg-amber-50">
                              {category.Product.length} ชิ้น
                            </td>
                            <td className="px-6 py-4 bg-orange-50 ">
                              <img
                                className="mx-auto"
                                src={config.img + category.Categoryimg}
                                alt={category.Categoryname}
                                style={{ maxWidth: "200px", height: "100px" }}
                              />
                            </td>
                            <td className="px-6 py-4 bg-orange-50">
                              {datajob ? (
                                <>
                                  {datajob.map((item, index) => (
                                    <>
                                      {item.id === category.JobId ? (
                                        <>{item.Jobname}</>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  ))}
                                </>
                              ) : (
                                <></>
                              )}
                            </td>
                            <td className="px-6 py-4 bg-red-50 ">
                              <div className="flex">
                                <button
                                  onClick={(e) => deltable(category)}
                                  className="btn btn-circle btn-outline btn-error w-2/4 h-2/4 mr-2"
                                >
                                  <i className="fa-sharp fa-solid fa-trash"></i>
                                </button>

                                <button
                                  onClick={(e) => edit(category)}
                                  className="btn btn-outline btn-info w-2/4 h-2/4 mr-2"
                                >
                                  <i className="fa-sharp fa-solid fa-screwdriver-wrench"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        </>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* <div className="col-span-12 sm:col-span-12 md:col-span-12 xl:col-span-4 text-xs sm:text-xs md:text-lg lg:text-lg">
          <div className="ml-10 ">
            {" "}
            <i className="fa-solid fa-medal mr-2"></i> 5 หมวดหมู่สินค้ายอดนิยม
          </div>
          <div className="relative flex flex-col min-w-0 mt-5 mb-6 ml-5 break-words bg-gray-100 dark:bg-gray-950 shadow-soft-xl dark:shadow-soft-dark-xl rounded-2xl bg-clip-border border-spacing-6 col-span-4">
            <div className="relative overflow-x-auto shadow-soft-xl dark:shadow-soft-dark-xl rounded-2xl bg-clip-border border-spacing-6">
              <table className="w-full text-sm text-left shadow rtl:text-right text-gray-500 dark:text-gray-400 ">
                <thead className="text-center text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3 rounded-s-lg bg-amber-50">
                      หมวดหมู่สินค้า
                    </th>
                    <th scope="col" className="px-6 py-3 bg-orange-50">
                      การซื้อสินค้า
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {topCategory ? (
                    <>
                      {topCategory.map((top, index) => (
                        <>
                          <tr
                            key={index}
                            className="bg-white dark:bg-gray-800 text-center"
                          >
                            <th
                              scope="row"
                              className="px-6 py-4 bg-amber-50 text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {top}
                            </th>
                            <td className="px-6 py-4 bg-orange-50">
                              {top}
                            </td>
                          </tr>
                        </>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
            </div>
          </div> 
        </div>*/}
      </Dashboard>
    </>
  );
}
