import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import config from "../config";
import axios from "axios";
import Swal from "sweetalert2";
function User3() {
  const Navigate = useNavigate();
  const [category, setcategory] = useState([]);
  const [Product, setProduct] = useState([]);
  const [cartItems, setcartItems] = useState([]);
  const [price, setprice] = useState([]);
  const [qrcode, setqrcode] = useState();
  const [Payment, setPayment] = useState();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const id = urlSearchParams.get("id");
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const resCategory = await axios.get(config.apipath + "get/category");
      setcategory(resCategory.data);

      const resProduct = await axios.get(config.apipath + "get/product");
      setProduct(resProduct.data);

      // const storedCartItems = await JSON.parse(localStorage.getItem("cartItems")) || [];
      const storedCartItems = JSON.parse(localStorage.getItem("cartItems"+id));

      setcartItems(storedCartItems);

      if (resProduct.data.length > 0) {
        const totalPrice = storedCartItems.reduce((accumulator, cartItem) => {
          return (
            accumulator +
            (resProduct.data.find(
              (product) => product.id === cartItem.Productid
            ).Producprice *
              cartItem.Productcount +
              cartItem.specialprice)
          );
        }, 0);

        setprice(totalPrice);
        const data ={
          amount: totalPrice
        }
        const qrcode = await axios.post(config.apipath + 'admin/generateQrcode', data);
        setqrcode(qrcode.data)
        // แสดงราคารวมทั้งหมดของสินค้าในตะกร้า
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const handleFileChange = (event) => {
    console.log(event.target)
    setPayment(event.target.files[0]);
  };


  const PaymentSubmit = async () => {
    try {
      if (id) {
        if (price) {
          if (Payment) {
            const formData = new FormData();
            
            const product = JSON.stringify(cartItems);
            formData.append("table", id);
            formData.append("price", price);
            formData.append("product", product);
            formData.append("file", Payment);
            console.log(formData)

            const response = await axios.post(
              config.apipath + "user/buyproductpp",
              formData,
              {
                headers: {
                  Authorization: "Bearer" + localStorage.getItem("Token"),
                  "Content-Type": "multipart/form-data",
                },
              },
            );
            if (response.data.id !== "") {
              localStorage.removeItem("cartItems"+id);
              localStorage.setItem("billid", response.data.id );
              Navigate(`/wait?id=${id}`);
            } else {
              Swal.fire({
                icon: "info",
                title: "ผิดพลาด",
                showConfirmButton: false,
                timer: 1500,
              });
            }
          } else {
            Swal.fire({
              icon: "info",
              title: "กรุณาแนบสลิปการโอนเงิน",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }
      }
    } catch (error) {
      console.log(error)
      Swal.fire({
        icon: "info",
        title: error.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  return (
    <>
      <div className="flex flex-col items-center border-b bg-white py-4 sm:flex-row sm:px-10 lg:px-20 xl:px-32">
        <a href="#" className="text-2xl font-bold text-gray-800">
        โต๊ะ {id}
        </a>
        <div className="mt-4 py-2 text-xs sm:mt-0 sm:ml-auto sm:text-base">
          <div className="relative">
            <ul className="relative flex w-full items-center justify-between space-x-2 sm:space-x-4">
              <li className="flex items-center space-x-3 text-left sm:space-x-4">
                <a
                  className="flex h-6 w-6 items-center justify-center rounded-full bg-emerald-200 text-xs font-semibold text-emerald-700"
                  href="#"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </a>
                <span className="font-semibold text-gray-900">Shop</span>
              </li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 5l7 7-7 7"
                />
              </svg>
              <li className="flex items-center space-x-3 text-left sm:space-x-4">
                <a
                  className="flex h-6 w-6 items-center justify-center rounded-full bg-emerald-200 text-xs font-semibold text-emerald-700"
                  href="#"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </a>
                <span className="font-semibold text-gray-900">Shipping</span>
              </li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 5l7 7-7 7"
                />
              </svg>
              <li className="flex items-center space-x-3 text-left sm:space-x-4">
                <a
                  className="flex h-6 w-6 items-center justify-center rounded-full bg-gray-600 text-xs font-semibold text-white ring ring-gray-600 ring-offset-2"
                  href="#"
                >
                  3
                </a>
                <span className="font-semibold text-gray-900">Payment</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="grid sm:px-10 lg:grid-cols-2 lg:px-20 xl:px-32">
        <div className="px-4 pt-8">
          <p className="text-xl font-medium">โปรดชำระเงิน</p>
          <p className="text-gray-400">โปรดชำละเงินภายใน5นาที</p>
          <div className="mt-8 space-y-3 rounded-lg border bg-white px-2 py-4 sm:px-6 ">
            <img className="w-5/6" src={qrcode} />
          </div>
          <p className="text-xl font-medium px-4 pt-8">
            โปรดแนบสลีป
            <input
              type="file"
              onChange={handleFileChange}
              className="file-input file-input-bordered file-input-sm w-full max-w-xs"
            />
          </p>
        </div>
        <div className="mt-10 bg-gray-50 px-4 pt-8 lg:mt-0">
          <p className="text-gray-400">
            เมื่อดำเนินการคำสั่งซื้อสำเร็จระบบจะนำท่านกลับไปยังหน้าต่างแรก
          </p>

          <button
            onClick={PaymentSubmit}
            className="mt-4 mb-8 w-full rounded-md bg-red-500 px-6 py-3 font-medium text-white"
          >
            เรียบร้อย
          </button>
        </div>
      </div>
    </>
  );
}
export default User3;
