import { useEffect, useState } from "react";
import BarEmployee from "../component/BarEmployee";
import BarProduct from "./BarProduct";
import axios from "axios";
import Swal from "sweetalert2";
import config from "../../config";
import { useNavigate } from "react-router-dom";

export default function Productlist() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const id = urlSearchParams.get("id");
  const [Product, setProduct] = useState("");
  const [category, setcategory] = useState("");
  const Navigate = useNavigate();

  useEffect(() => {
    payload()
    dataProduct();
  }, []);
  const payload = async () => {
    try {
      const res = await axios.get(
        config.apipath + "admin/token",
        config.headers()
      );
      if (res.data.payload === undefined) {
        Navigate('/')
      } 
    } catch (error) {
      
    }
  };
  const dataProduct = async () => {
    try {
      const res = await axios.get(
        config.apipath + "admin/product/" + id,
        config.headers()
      );

      const category = await axios.get(
        config.apipath + "admin/category/" + id,
        config.headers()
      );
      setcategory(category.data);
      setProduct(res.data);
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "เกิดข้อผิดพลาด",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const del = async (item) => {
    try {
      if (item === "") {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      Swal.fire({
        title: "ยืนยันการลบรายการ",
        text: "กรณีลบรายการไปแล้วจะไม่สามารถเรียกคืนค่าได้อีก ต้องเพิ่มใหม่เท่านั้น",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const del = await axios.delete(
            config.apipath + "admin/deleteproduct/" + item.id,
            config.headers()
          );
          dataProduct();
          if (del.data.message === "success") {
            Swal.fire({
              title: "ลบรายการสำเร็จ",
              text: "Your file has been deleted.",
              icon: "success",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "เกิดข้อผิดพลาด",
              showConfirmButton: false,

              timer: 1500,
            });
          }
        }
      });
    } catch {
      Swal.fire({
        icon: "error",
        title: "เกิดข้อผิดพลาด",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const edit = async (item) => {
    try {
      if (item.id !== "") {
        const url = "/productedit?id=" + item.id;
        Navigate(url);
      }
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "เกิดข้อผิดพลาด",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  return (
    <>
      <BarEmployee>
        <div className="mt-5 h-auto col-span-6 m-2 rounded-lg sm:grid-cols-8">
          <BarProduct
            name={category.Categoryname}
            id={category.id}
            count={Product.length}
          />
          <div className="mt-5  rounded-lg">
            <div className="grid gap-x-2 gap-y-10 grid-cols-4 px-5 ml-2 ">
              {Product.length > 0 ? (
                <>
                  {Product.map((product, index) => (
                    <>
                      <div
                        key={index}
                        className="card w-4-5 bg-base-100 shadow-xl mt-3 hover:bg-orange-100 mb-5 "
                      >
                        <div className="flex justify-end">
                          <details className="dropdown">
                            <summary className="m-1">
                              <svg
                                className="w-5 h-5 inline-block mr-2"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 16 3"
                              >
                                <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z" />
                              </svg>
                            </summary>
                            <ul className="p-2 shadow menu dropdown-content z-[1] bg-base-100 rounded-box w-52">
                              <li onClick={(e) => edit(product)}>
                                <a>แก้ไขสินค้า</a>
                              </li>
                              <li onClick={(e) => del(product)}>
                                <a>ลบสินค้า</a>
                              </li>
                            </ul>
                          </details>
                        </div>
                        <figure>
                          <img
                            src={config.img + product.Productimg}
                            alt="Shoes"
                            className="rounded-md m-3"
                            style={{ height: "150px", width: "150px" }}
                          />
                        </figure>

                        <h2 className="card-title justify-center mt-3 text-sky-900">
                          {product.Productname}
                        </h2>

                        <div className="card-actions justify-center m-2">
                          <div className="badge badge-outline text-sky-900">
                            {category.Categoryname}
                          </div>
                          <div className="badge badge-outline text-sky-900">
                            ราคาสินค้า : {product.Producprice}
                          </div>
                          <div className="badge badge-outline text-sky-900">
                            เหลือ : {product.Productcount}
                          </div>

                          <br />
                        </div>
                      </div>
                    </>
                  ))}
                </>
              ) : (
                <>
                  <div className="h-96 m-10">ไม่มีข้อมูลสินค้า</div>
                </>
              )}
            </div>
          </div>
        </div>
      </BarEmployee>
    </>
  );
}
