import { useNavigate } from "react-router-dom";
import config from "../config";

import axios from "axios";
import Swal from "sweetalert2";
import React from "react";
import Dashboard from "./dashboard";
import { useEffect, useState } from "react";
function OrderShow() {
  const Navigate = useNavigate();
  const [order, setorder] = useState([]);
  useEffect(() => {
    fetchData();
  }, [order]);
  const payload = async () => {
    try {
      const res = await axios.get(
        config.apipath + "admin/token",
        config.headers()
      );
      if (res.data.payload === undefined) {
        Navigate("/");
      }
    } catch (error) {}
  };
  const fetchData = async () => {
    const res = await axios.get(
      config.apipath + "get/Billsale",
      config.headers()
    );
    setorder(res.data);
  };

  const toorder = (item) => {
    Navigate(`/ownerordernew?id=${item.id}`);
  };

  return (
    <>
      <Dashboard>
        <div className=" col-span-12 text-xs sm:text-xs md:text-lg lg:text-lg m-5">
          <div className="relative flex flex-col min-w-0 mt-5 mb-6 ml-5 break-words  dark:bg-gray-950 shadow-soft-xl dark:shadow-soft-dark-xl rounded-2xl bg-clip-border border-spacing-6 col-span-12">
            <div className="py-8 m-3">
              <div className="container mx-auto px-4">
                <h1 className="text-2xl font-semibold mb-4">คำสั่งซื้อ</h1>
                <div className="flex flex-col md:flex-row gap-4">
                  <div className=" w-full ">
                    <div className="relative overflow-x-auto shadow-soft-xl dark:shadow-soft-dark-xl rounded-2xl bg-clip-border border-spacing-6">
                      <table className="w-full text-sm text-left shadow rtl:text-right text-gray-500 dark:text-gray-400 ">
                        <thead className="text-center text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                          <tr>
                            <th className="text-left font-semibold px-6 py-3 ">
                              โต๊ะ
                            </th>
                            <th className="text-center font-semibold px-6 py-3 ">
                              ช่องทางการจ่ายเงิน
                            </th>
                            <th className="font-semibold text-center px-6 py-3 ">
                              จำนวน
                            </th>

                            <th className="font-semibold text-center px-6 py-3 ">
                              เป็นจำนวนเงิน
                            </th>
                            <th className="font-semibold text-center px-6 py-3 ">
                              สถานะ
                            </th>
                            <th className="font-semibold text-center px-6 py-3 ">
                              การทำ
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {order.length > 0 ? (
                            <>
                              {order.map((order, index) => (
                                <>
                                  <tr className="h-20">
                                    <td className="py-4 px-6">
                                      <div className="flex items-center">
                                        <span className="font-semibold">
                                          {order.tableid}
                                        </span>
                                      </div>
                                    </td>
                                    <td className="py-4 text-center">
                                      {order.payment.length > 0 ? (
                                        <>
                                          {order.payment.length > 0 ? (
                                            <>
                                              {order.payment.map((payment) => (
                                                <>
                                                  {payment.paymentimg !==
                                                  "0" ? (
                                                    <>พร้อมเพย์</>
                                                  ) : (
                                                    <>เงินสด</>
                                                  )}
                                                </>
                                              ))}
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </td>
                                    <td className="py-4 px-6 text-center">
                                      {order.BillsaleDetail.length}
                                    </td>
                                    <td className="py-4 px-6 text-center ">
                                      {order.pricetotal} บาท
                                    </td>

                                    <td className="py-4 px-6 text-center ">
                                      {order.Billsalestatus === 1 ? (
                                        <span className="text-sky-500">กำลังดำเนินการ </span>
                                      ) : order.Billsalestatus === 2 ? (
                                        <span className="text-green-500">ดำเนินการเสร็จสิ้น</span>
                                      ) : (
                                        <span className="text-red-500">ยังไม่ได้ดำเนินการ</span>
                                      )}
                                    </td>
                                    <td className="py-4 px-6 text-center ">
                                      <i
                                        onClick={(e) => toorder(order)}
                                        className="fa-regular fa-eye mr-5 bg-green-50 w-1/4 h-1/4 text-green-800 rounded-lg"
                                      ></i>
                                      {/* <i className="fa-solid fa-trash text-red-500 bg-red-50 w-1/4 h-1/4 rounded-lg"></i> */}
                                    </td>
                                  </tr>
                                </>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                          {/* More product rows */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dashboard>
    </>
  );
}

export default OrderShow;
