import React from "react";

class ComponentToPrint extends React.Component {
  render() {
    const { img, table } = this.props;

    return (
      <div>
        <style>
          {`
            @media print {
              @page {
                size: 80mm auto; /* ตั้งค่าขนาดกระดาษเป็น 80mm และความสูงปรับตามข้อมูล */
              }
            }
          `}
        </style>
        <div className="overflow-y-auto text-xs  overflow-x-hidden inset-0 z-50 flex justify-center items-center  ">
          <div>
            {/* <img className='h-32 w-32' src='https://cdn.discordapp.com/attachments/1210600083574497351/1220770251193585674/2.png?ex=661025e4&is=65fdb0e4&hm=6d73e2e62a096e4743ef894486c48ac5c816663a0635101df51f3ea83d96f9dc&' /> */}
            <h1 className="text-lg text-wrap text-center font-semibold">
              คาเฟ่แห่งหนึ่ง
            </h1>
            <h1 className="text-md text-wrap text-center ">123 ม.3 ต. อ. จ.</h1>
            <h1 className="text-xs text-wrap text-center "> โทร 00000000</h1>
            <hr className="my-2" />

            <h1 className="text-sm text-wrap "> โต๊ะที่ {table}</h1>
            <hr className="my-2" />

            <div className=" flex justify-center">
              <img className="h-32 w-32 " src={img} />
            </div>

            <hr className="my-2" />
            <div className="flex justify-center mb-2">
              <span className="text-sm">สั่งอาการและเครื่องดื่มได้เลย </span>
            </div>
            <div className="flex justify-center mb-2">
              <span className="text-sm">เพี่ยงท่านสแกน Qr Code ! </span>
            </div>

            <div className="flex justify-between mt-10">
              <span className="font-semibold"> </span>
              <span className="text-xs">By BSI</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ComponentToPrint; // Export ComponentToPrint as default
