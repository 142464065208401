import Dashboard from "./dashboard";
import { useEffect, useState } from "react";
import axios from "axios";
import config from "../config";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
export default function DashbordCounter() {
  const Navigate = useNavigate();
  const [job, setjob] = useState("");
  const [jobid, setjobid] = useState("");
  const [jobfile, setjobfile] = useState("");
  const [jobimg, setjobimg] = useState("");
  const [jobname, setjobname] = useState("");
  const [button, setbutton] = useState(false);
  const [btn, setbtn] = useState("");
  useEffect(() => {
    payload();
    data();
  }, []);

  const payload = async () => {
    try {
      const res = await axios.get(
        config.apipath + "admin/token",
        config.headers()
      );
      if (res.data.payload === undefined) {
        Navigate("/");
      }
    } catch (error) {}
  };

  const data = async () => {
    try {
      const res = await axios.get(
        config.apipath + "admin/job",
        config.headers()
      );
      setjob(res.data);
      // setcount(res.data.tablecount);
      // settopCategory(res.data.topTablesWithBillsaleCount);
    } catch (e) {}
  };

  const handleFileChange = (event) => {
    setjobfile(event.target.files[0]);
  };

  const add = async () => {
    await setbutton(!button);
    setbtn("add");
    setjobname("");
    setjobimg("");
  };

  const edit = async (item) => {
    await setbutton(!button);
    setbtn("edit");
    setjobid(item.id);
    setjobimg(item.Jobimg);
    setjobname(item.Jobname);
  };

  const addcounter = async () => {
    try {
      if (jobname !== "") {
        if (jobfile !== "") {
          const formData = new FormData();
          formData.append("job", jobname);
          formData.append("file", jobfile);

          const response = await axios.post(
            config.apipath + "admin/addjob",
            formData,
            {
              headers: {
                Authorization: "Bearer" + localStorage.getItem("Token"),
                "Content-Type": "multipart/form-data",
              },
            },
            config.headers()
          );
          if (response.data.message === "success") {
            await Swal.fire({
              icon: "success",
              title: "เพิ่มเคาน์เตอร์สำเร็จ",
              showConfirmButton: false,
              timer: 1500,
            });
            setjob("");
            setjobfile("");
            data();
            await setbutton(!button);
          } else {
            await Swal.fire({
              icon: "info",
              title: response.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } else {
          Swal.fire({
            icon: "info",
            title: "กรุณาแนบรูป",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        Swal.fire({
          icon: "info",
          title: "กรุณากรอกชื่อเคาน์เตอร์",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {}
  };

  const editcounter = async () => {
    try {
      if (jobname !== "") {
          const formData = new FormData();
          formData.append("id", jobid);
          formData.append("name", jobname);
          formData.append("file", jobfile);

          const response = await axios.put(
            config.apipath + "admin/updatejob",
            formData,
            {
              headers: {
                Authorization: "Bearer" + localStorage.getItem("Token"),
                "Content-Type": "multipart/form-data",
              },
            },
            config.headers()
          );
          if (response.data.message === "success") {
            await Swal.fire({
              icon: "success",
              title: "เพิ่มเคาน์เตอร์สำเร็จ",
              showConfirmButton: false,
              timer: 1500,
            });
            setjob("");
            setjobfile("");
            data();
            await setbutton(!button);
          } else {
            await Swal.fire({
              icon: "info",
              title: response.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        
      } else {
        Swal.fire({
          icon: "info",
          title: "กรุณากรอกชื่อเคาน์เตอร์",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {}
  };

  const del = async(item) => {
    try {
      payload();
      if (item === "") {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      Swal.fire({
        title: "ยืนยันการลบรายการ",
        text: "กรณีลบรายการไปแล้วจะไม่สามารถเรียกคืนค่าได้อีก ต้องเพิ่มใหม่เท่านั้น",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const del = await axios.delete(
            config.apipath + "admin/deletejob/" + item.id,
            config.headers()
          );
          data();
          if (del.data.message === "success") {
            Swal.fire({
              title: "ลบรายการสำเร็จ",
              text: "Your file has been deleted.",
              icon: "success",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "เกิดข้อผิดพลาด",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }
      });
    } catch (error) {
      
    }
  }

  return (
    <>
      <Dashboard>
        <div className=" col-span-12 text-xs sm:text-xs md:text-lg lg:text-lg">
          {" "}
          จัดการเคาน์เตอร์
        </div>
        <div className="col-span-12 sm:col-span-12 md:col-span-12 xl:col-span- text-xs sm:text-xs md:text-lg lg:text-lg">
          <div className="relative flex flex-col min-w-0 mt-5 mb-6 ml-5 break-words bg-gray-100 dark:bg-gray-950 shadow-soft-xl dark:shadow-soft-dark-xl rounded-2xl bg-clip-border col-span-12 sm:col-span-12 md:col-span-12 xl:col-span-12">
            <div className="stats stats-vertical lg:stats-horizontal shadow grid grid-cols-1">
              <div className="stat col-span-1 text-center">
                <div className="stat-title">จำนวนเคาน์เตอร์ทั้งหมด</div>
                <div className="stat-value">{job.length}</div>
              </div>
            </div>
          </div>

          {button ? (
            <div className="animate-fadeIn r">
              <div className="elative grid grid-cols-12 min-w-0 mt-5 mb-6 ml-5 break-words bg-gray-100 dark:bg-gray-950 shadow-soft-xl dark:shadow-soft-dark-xl rounded-2xl bg-clip-border col-span-12 sm:col-span-12 md:col-span-12 xl:col-span-12">
                <div className="stats stats-vertical lg:stats-horizontal shadow col-span-12 grid grid-cols-13">
                  <div className="stat col-span-3 sm:col-span-12 md:col-span-12 lg:col-span-12  xl:col-span-3 text-center">
                    <label className="input input-bordered input-accent flex items-center gap-2 w-full ">
                      ชื่อ
                      <input
                        type="text"
                        className="grow"
                        placeholder="เคาน์เตอร์"
                        onChange={(e) => setjobname(e.target.value)}
                        value={jobname}
                      />
                    </label>
                  </div>

                  {jobimg ? (
                    <>
                      <div className="stat col-span-3 sm:col-span-12 md:col-span-12 lg:col-span-12  xl:col-span-3 text-center">
                        <img
                          className="mx-auto"
                          src={config.img + jobimg}
                          style={{ maxWidth: "200px", height: "100px" }}
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="stat col-span-3 sm:col-span-12 md:col-span-12 lg:col-span-12  xl:col-span-3 text-center">
                    <input
                      onChange={handleFileChange}
                      type="file"
                      className="file-input file-input-bordered file-input-accent flex items-center gap-2 w-full grow"
                    />
                  </div>
                  <div className="stat col-span-3 sm:col-span-12 md:col-span-12 lg:col-span-6  xl:col-span-3 text-center">
                    {btn === "add" ? (
                      <button
                        onClick={addcounter}
                        className="btn btn-square btn-outline btn-accent flex items-center gap-2 w-full "
                      >
                        เพิ่มเคาน์เตอร์
                      </button>
                    ) : (
                      <button
                        onClick={editcounter}
                        className="btn btn-square btn-outline btn-accent flex items-center gap-2 w-full "
                      >
                        แก้ไขเคาน์เตอร์
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className="relative flex flex-col min-w-0 mt-5 mb-6 ml-5 break-words  dark:bg-gray-950 shadow-soft-xl dark:shadow-soft-dark-xl rounded-2xl bg-clip-border border-spacing-6 col-span-12">
            <div className="ml-1 col-span-10 ">
              {" "}
              <i className="fa-sharp fa-solid fa-chair mr-2"></i>
              เคาน์เตอร์ทั้งหมด
              <button
                onClick={add}
                className="btn btn-square btn-outline btn-accent col-span-1 w-1/6 h-auto m-5"
              >
                <i className="fa-sharp fa-solid fa-plus"></i>
              </button>
            </div>
            <div className="relative overflow-x-auto shadow-soft-xl dark:shadow-soft-dark-xl rounded-2xl bg-clip-border border-spacing-6">
              <table className="w-full text-sm text-left shadow rtl:text-right text-gray-500 dark:text-gray-400 ">
                <thead className="text-center text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3 rounded-s-lg bg-yellow-50">
                      ชื่อ
                    </th>
                    <th scope="col" className="px-6 py-3 rounded-s-lg bg-amber-50">
                      หมวดหมู่
                    </th>
                    <th scope="col" className="px-6 py-3 bg-orange-50">
                      รูป
                    </th>
                    <th scope="col" className="px-6 py-3 bg-red-50">
                      ตั้งค่า
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {job ? (
                    <>
                      {job.map((Job, index) => (
                        <>
                          <tr
                            key={index}
                            className="bg-white dark:bg-gray-800 text-center"
                          >
                            <th
                              scope="row"
                              className="px-6 py-4 bg-yellow-50 text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {Job.Jobname}
                            </th>
                            <th
                              scope="row"
                              className="px-6 py-4 bg-amber-50 text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {Job.Category.length}
                            </th>
                            <th
                              scope="row"
                              className="px-6 py-4 bg-orange-50 text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              <img
                                className="mx-auto"
                                src={config.img + Job.Jobimg}
                                style={{ maxWidth: "200px", height: "100px" }}
                              />
                            </th>
                            <td className="px-6 py-4 bg-red-50 ">
                              <div className="flex">
                                <button
                                  onClick={(e) => del(Job)}
                                  className="btn btn-circle btn-outline btn-error w-2/4 h-2/4 mr-2"
                                >
                                  <i className="fa-sharp fa-solid fa-trash"></i>
                                </button>

                                <button
                                  onClick={(e) => edit(Job)}
                                  className="btn btn-outline btn-info w-2/4 h-2/4 mr-2"
                                >
                                  <i className="fa-sharp fa-solid fa-screwdriver-wrench"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        </>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Dashboard>
    </>
  );
}
