import React from "react";

class ComponentToPrint extends React.Component {
  render() {
    const { data } = this.props;
    return (
      <div>
        <style>
          {`
            @media print {
              @page {
                size: 80mm auto; /* ตั้งค่าขนาดกระดาษเป็น 80mm และความสูงปรับตามข้อมูล */
              }
            }
          `}
        </style>
        <div className="overflow-y-auto text-xs  overflow-x-hidden inset-0 z-50 flex justify-center items-center  ">
          {data ? (
            <>
              <div>
                {/* <img className='h-32 w-32' src='https://cdn.discordapp.com/attachments/1210600083574497351/1220770251193585674/2.png?ex=661025e4&is=65fdb0e4&hm=6d73e2e62a096e4743ef894486c48ac5c816663a0635101df51f3ea83d96f9dc&' /> */}
                <h1 className="text-lg text-wrap text-center font-semibold">
                  คาเฟ่แห่งหนึ่ง
                </h1>
                <h1 className="text-md text-wrap text-center ">123 แม่กา</h1>
                <h1 className="text-xs text-wrap text-center ">
                  {" "}
                  โทร 00000000
                </h1>
                <hr className="my-2" />

                <h1 className="text-sm text-wrap "> โต๊ะที่ {data.table}</h1>
                <hr className="my-2" />

                <table className="w-full text-xs  text-left rtl:text-right  ">
                  <thead className="text-center uppercase mb-2 ">
                    <tr className="border-b">
                      <th className="w-1/6 text-center">จำนวน</th>
                      <th>สินค้า</th>
                      <th>พิเศษ</th>
                      <th>เพิ่มเติม</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {data.product ? (
                      <>
                        {data.product.map((product) => (
                          <>
                            <tr className="text-xs h-9">
                              <td className="w-1/6 text-center">
                                {product.Productcount}
                              </td>
                              <td className="w-1/6 text-center">
                                {product.name}
                              </td>
                              <td className="w-1/6 text-center">
                                {product.special}
                              </td>
                              <td className="w-1/6 text-center">
                                {product.Description}
                              </td>
                            </tr>
                          </>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
                {/* <hr className="my-2 border-solid border-2 border-stone-500" /> */}
                {/* <div className="flex justify-between mb-2">
                      <span>ยอดทั้งหมด</span>
                      <span></span>
                    </div>
                    <div className="flex text-xs  justify-between mb-2">
                      <span>ภาษี</span>
                      <span>$0.00</span>
                    </div>
                    <div className="flex text-xs  justify-between mb-2">
                      <span>การส่งของ</span>
                      <span>$0.00</span>
                    </div>
                    <hr className="my-2" />
                    <div className="flex text-xs  justify-between mb-2">
                      <span className="font-semibold">รวมทั้งหมด</span>
                      <span className="font-semibold"></span>
                    </div> */}


                <hr className="my-2" />
                <div className="flex justify-between mb-2">
                  <span className="font-semibold">เคาน์เตอร์ </span>
                  <span className="font-semibold">{data.name}</span>
                </div>

               <hr className="my-2" />
                <div className="flex justify-center mb-2">
                  <span className="text-sm">ขอบคุณที่ใช้บริการ </span>
                </div>

                <div className="flex justify-between mt-10">
                  <span className="font-semibold"> </span>
                  <span className="text-xs">By BSI</span>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }
}

export default ComponentToPrint; // Export ComponentToPrint as default
