import { useNavigate } from "react-router-dom";

export default function Barorder() {
  const Navigate = useNavigate();
  return (
        <>
        <div className="grid gap-x-8 gap-y-4 grid-cols-5">
            <button onClick={(e) => Navigate("/neworder")} className=" bg-amber-50 px-4 py-2 rounded-md text-sm font-medium  hover:scale-110  hover:bg-amber-100 duration-300">
              คำสั่งซื้อ
            </button>
            <button onClick={(e) => Navigate("/Neworderwait")}  className="bg-amber-50 px-4 py-2 rounded-md text-sm font-medium hover:scale-110 hover:bg-amber-100 duration-300 ">
              กำลังดำเนินการ
            </button>
            <button
             onClick={(e) => Navigate("/NeworderSuccess")} 
              className="bg-amber-50 px-4 py-2 rounded-md text-sm font-medium hover:scale-110 hover:bg-amber-100 duration-300"
            >
              ทำรายการเสร็จสิ้น
            </button>
            <button onClick={(e) => Navigate("/ordershow")}  className="bg-amber-50 px-4 py-2 rounded-md text-sm font-medium hover:scale-110 hover:bg-amber-100 duration-300" >
              บันทึกรายการขาย
            </button>
           
            
          </div>
        </>
    );
}