import { useNavigate, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import config from "../config";
import axios from "axios";
import promptpay from "../img/promptpay.png";
import Swal from "sweetalert2";
import monney from "../img/monney.png";
function User2() {
  const [category, setcategory] = useState([]);
  const [Product, setProduct] = useState([]);
  const [cartItems, setcartItems] = useState([]);
  const [price, setprice] = useState(0);
  const [Payment, setPayment] = useState("");
  const urlSearchParams = new URLSearchParams(window.location.search);
  const id = urlSearchParams.get("id");
  const Navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const resCategory = await axios.get(config.apipath + "get/category");
      setcategory(resCategory.data);
      const resProduct = await axios.get(config.apipath + "get/product");
      setProduct(resProduct.data);
      const storedCartItems = JSON.parse(
        localStorage.getItem("cartItems" + id)
      );

      setcartItems(storedCartItems);

      if (resProduct.data.length > 0) {
        if (storedCartItems.length > 0) {
          const totalPrice = storedCartItems.reduce((accumulator, cartItem) => {
            return (
              accumulator +
              (resProduct.data.find(
                (product) => product.id === cartItem.Productid
              ).Producprice *
                cartItem.Productcount +
                cartItem.specialprice * cartItem.Productcount)
            );
          }, 0);

          setprice(totalPrice);
        } else {
        }
      } else {
      }
    } catch (error) {}
  };

  const totable = async () => {
    Navigate(`/table?id=${id}`);
  };

  const remove = (index) => {
    setcartItems((cartItems) => {
      const del = cartItems.filter((item, i) => i !== index);
      const totalPrice = del.reduce((accumulator, del) => {
        return (
          accumulator +
          (Product.find((product) => product.id === del.Productid).Producprice +
            del.specialprice)
        );
      }, 0);
      setprice(totalPrice);
      localStorage.setItem("cartItems" + id, JSON.stringify(del));
      fetchData();
      return del;
    });
  };

  const PaymentSubmit = async () => {
    try {
      if (Payment === "cash") {
        if (id) {
          if (price) {
            const formData = new FormData();

            const product = JSON.stringify(cartItems);
            formData.append("table", id);
            formData.append("price", price);
            formData.append("product", product);

            const data = {
              table: id,
              price: price,
              product: cartItems,
            };

            const response = await axios.post(
              config.apipath + "user/buyproduct",
              data
            );
            if (response.data.id !== "") {
              localStorage.removeItem("cartItems" + id);
              localStorage.setItem("billid", response.data.id);
              Navigate(`/wait?id=${id}`);
            }
            // localStorage.removeItem("cartItems");
          } else {
            localStorage.removeItem("cartItems" + id);
            Swal.fire({
              icon: "info",
              title: "ผิดพลาด",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } else {
          localStorage.removeItem("cartItems" + id);
          Swal.fire({
            icon: "info",
            title: "ผิดพลาด",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else if (Payment === "promptpay") {
        Navigate(`/payment?id=${id}`);
      } else {
        Swal.fire({
          icon: "info",
          title: "กรุณาเลือกช่องทางการชำระเงิน",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
          localStorage.removeItem("cartItems" + id);
          localStorage.removeItem("cartItems");
          window.location.reload();
    }
  };

  return (
    <>
      <div className="flex flex-col items-center border-b bg-white py-4 sm:flex-row sm:px-10 lg:px-20 xl:px-32">
        <a href="#" className="text-2xl font-bold text-gray-800">
          โต๊ะ {id}
        </a>
        <div className="mt-4 py-2 text-xs sm:mt-0 sm:ml-auto sm:text-base">
          <div className="relative">
            <ul className="relative flex w-full items-center justify-between space-x-2 sm:space-x-4">
              <li
                onClick={totable}
                className="flex items-center space-x-3 text-left sm:space-x-4"
              >
                <a
                  className="flex h-6 w-6 items-center justify-center rounded-full bg-emerald-200 text-xs font-semibold text-emerald-700"
                  href="#"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </a>
                <span className="font-semibold text-gray-900">Shop</span>
              </li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 5l7 7-7 7"
                />
              </svg>
              <li className="flex items-center space-x-3 text-left sm:space-x-4">
                <a
                  className="flex h-6 w-6 indicator items-center justify-center rounded-full bg-gray-600 text-xs font-semibold text-white ring ring-gray-600 ring-offset-2"
                  href="#"
                >
                  {cartItems ? (
                    <>
                      <span className="indicator-item badge badge-secondary">
                        {cartItems.length}
                      </span>
                    </>
                  ) : (
                    <></>
                  )}{" "}
                  2
                </a>
                <span className="font-semibold text-gray-900">
                  Shipping Cart
                </span>
              </li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 5l7 7-7 7"
                />
              </svg>
              <li className="flex items-center space-x-3 text-left sm:space-x-4">
                <a
                  className="flex h-6 w-6 items-center justify-center rounded-full bg-gray-400 text-xs font-semibold text-white"
                  href="#"
                >
                  3
                </a>
                <span className="font-semibold text-gray-500">Payment</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="grid sm:px-10 lg:grid-cols-2 lg:px-20 xl:px-32">
        <div className="px-4 pt-8">
          <p className="text-xl font-medium">สรุปการสั่งซื้อ</p>
          <p className="text-gray-400">ตรวจสอบรายการของคุณ</p>
          <div className="mt-8 space-y-3 rounded-lg border bg-white px-2 py-4 sm:px-6">
            {cartItems ? (
              <>
                {Product ? (
                  <>
                    {Product.length > 0 ? (
                      <>
                        {cartItems.length > 0 ? (
                          <>
                            {cartItems.map((cartItems, index) => (
                              <>
                                {Product.map((Product) => (
                                  <>
                                    {cartItems.Productid == Product.id ? (
                                      <>
                                        <div className="container mx-auto flex items-center">
                                          <div className="image flex justify-center w-24 h-24 mr-4">
                                            <img
                                              className="ml-10 h-24 w-28 rounded-md border object-cover object-center"
                                              src={
                                                config.img + Product.Productimg
                                              }
                                              alt="ตัวอย่างรูปภาพ"
                                            />
                                          </div>
                                          <div className="m-4  w-2/4 h-2/4">
                                            <div className="flex w-full flex-col px-4 py-4">
                                              <span className="font-semibold text-lg">
                                                {Product.Productname}
                                              </span>
                                              <span className="float-right text-gray-400 flex">
                                                <p className="float-right text-gray-400">
                                                  {Product.Producprice +
                                                    cartItems.specialprice}{" "}
                                                  บาท
                                                </p>
                                                <p className="float-right text-gray-400 ml-2">
                                                  {cartItems.special}
                                                </p>
                                              </span>
                                              <p className="float-right text-gray-400 flex ">
                                                จำนวน
                                                <p className="float-right text-gray-400 ml-2">
                                                  {cartItems.Productcount} ชิ้น
                                                </p>
                                              </p>
                                              <p className="float-right text-gray-400 flex ">
                                                รายละเอียด :
                                                <p className="float-right text-gray-400 ml-2">
                                                  {cartItems.Description}
                                                </p>
                                              </p>
                                            </div>
                                          </div>
                                          <div className="grid justify-items-end  w-2/4 h-2/4 mr-2  ">
                                            <button
                                              onClick={(e) => remove(index)}
                                              className="btn btn-circle btn-outline btn-error w-5/4 h-2/4 mr-5"
                                            >
                                              <i className="fa-sharp fa-solid fa-trash"></i>
                                            </button>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                ))}
                              </>
                            ))}
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </div>

          <p className="mt-8 text-lg font-medium">เลือกวิธีการชำระเงิน</p>
          <form className="mt-5 grid gap-6">
            <div className="relative">
              <input
                className="peer hidden"
                id="radio_1"
                type="radio"
                name="paymentMethod"
                value="promptpay"
                checked={Payment === "promptpay"}
                onChange={(e) => setPayment(e.target.value)}
              />
              <span className="peer-checked:border-gray-700 absolute right-4 top-1/2 box-content block h-3 w-3 -translate-y-1/2 rounded-full border-8 border-gray-300 bg-white"></span>
              <label
                className="peer-checked:border-2 peer-checked:border-gray-700 peer-checked:bg-gray-50 flex cursor-pointer select-none rounded-lg border border-gray-300 p-4"
                htmlFor="radio_1"
              >
                <img
                  className="w-14 object-contain"
                  src={promptpay}
                  alt=""
                  style={{ height: "50px", width: "50px" }}
                />
                <div className="ml-5">
                  <span className="mt-2 font-semibold">
                    Prompt Pay พร้อมเพย์
                  </span>
                  <p className="text-slate-500 text-sm leading-6">
                    ระบบจะทำรายการ 1-2 นาที
                  </p>
                </div>
              </label>
            </div>
            <div className="relative">
              <input
                className="peer hidden"
                id="radio_2"
                type="radio"
                name="paymentMethod"
                value="cash"
                checked={Payment === "cash"}
                onChange={(e) => setPayment(e.target.value)}
              />
              <span className="peer-checked:border-gray-700 absolute right-4 top-1/2 box-content block h-3 w-3 -translate-y-1/2 rounded-full border-8 border-gray-300 bg-white"></span>
              <label
                className="peer-checked:border-2 peer-checked:border-gray-700 peer-checked:bg-gray-50 flex cursor-pointer select-none rounded-lg border border-gray-300 p-4"
                htmlFor="radio_2"
              >
                <img
                  className="w-14 object-contain"
                  src={monney}
                  alt=""
                  style={{ height: "50px", width: "50px" }}
                />
                <div className="ml-5">
                  <span className="mt-2 font-semibold">เงินสด</span>
                  <p className="text-slate-500 text-sm leading-6">
                    ระบบจะทำรายการ 1-2 นาที
                  </p>
                </div>
              </label>
            </div>
          </form>
        </div>
        <div className="mt-10 bg-gray-50 px-4 pt-8 lg:mt-0">
          <p className="text-xl font-medium">รายละเอียดการจ่ายเงิน</p>
          <p className="text-gray-400">
            ดำเนินการคำสั่งซื้อของคุณให้เสร็จสิ้นโดยระบุรายละเอียดการชำระเงินของคุณ
          </p>
          <div className="">
            <div className="mt-6 border-t border-b py-2">
              <div className="flex items-center justify-between">
                <p className="text-sm font-medium text-gray-900">ราคาสินค้า</p>
                <p className="font-semibold text-gray-900">
                  {price ? <>{price}</> : <>0</>} บาท
                </p>
              </div>
              <div className="flex items-center justify-between">
                <p className="text-sm font-medium text-gray-900">ค่าบริการ</p>
                <p className="font-semibold text-gray-900">00.00 บาท</p>
              </div>
            </div>
            <div className="mt-6 flex items-center justify-between">
              <p className="text-sm font-medium text-gray-900">ยอดรวมทั้งหมด</p>
              <p className="text-2xl font-semibold text-gray-900">
                {price ? <>{price}</> : <>0</>} บาท
              </p>
            </div>
          </div>
          <button
            onClick={PaymentSubmit}
            className="mt-4 mb-8 w-full rounded-md bg-red-500 px-6 py-3 font-medium text-white"
          >
            ยืนยันคำสั่งซื้อ
          </button>
        </div>
      </div>
    </>
  );
}
export default User2;
