import React, { useEffect, useState } from "react";
import Slider from "react-slick"; // import Slider component
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Tapuser from "./CartContext";
import { useNavigate } from "react-router-dom";
import Join from "./modal";
import config from "../config";
import axios from "axios";
function User1() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const id = urlSearchParams.get("id");
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  const Navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [banner, setbanner] = useState([]);
  const [category, setcategory] = useState([]);
  const [Product, setProduct] = useState([]);
  const [special, setspecial] = useState([]);
  const [cartItems, setcartItems] = useState([]);
  const [price, setprice] = useState([]);
  const [Items, setItems] = useState([]);
  const [valuespecial, setvaluespecial] = useState([]);
  const [description, setdescription] = useState("");
  const [count, setCount] = useState(0);
  useEffect(() => {
    fetchdata();
  }, []);

  const fetchdata = async () => {
    try {
      const resbanner = await axios.get(config.apipath + "get/banner");
      setbanner(resbanner.data);
      const rescategory = await axios.get(config.apipath + "get/category");
      setcategory(rescategory.data);
      const resProduct = await axios.get(config.apipath + "get/product");
      setProduct(resProduct.data);
      const storedCartItems =
        JSON.parse(localStorage.getItem("cartItems"+id)) || [];
      setcartItems(storedCartItems);

      if (resProduct.data.length > 0) {
        if (storedCartItems.length !== undefined) {
          if (storedCartItems && resProduct.data) {
            const totalPrice = storedCartItems.reduce(
              (accumulator, cartItem) => {
                const product = resProduct.data.find(
                  (product) => product.id === cartItem.Productid
                );

                if (product) {
                  const itemPrice =
                    product.Producprice * cartItem.Productcount +
                    cartItem.specialprice * cartItem.Productcount;
                  return accumulator + itemPrice;
                } else {
                  return accumulator;
                }
              },
              0
            );
            setprice(...price, totalPrice);
          } else {
          }
        } else {
        }
      } else {
      }
    } catch (error) {}
  };

  const openModal = async (item) => {
    const special = await axios.get(
      config.apipath + "get/setproductlist/" + item.id
    );
    setItems(item);
    setIsOpen(true);
    setCount(1);
    fetchdata();
    setspecial(special.data);

  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const Counter = () => {
    const increment = () => {
      setCount((prevCount) => prevCount + 1);
    };

    const decrement = () => {
      setCount((prevCount) => prevCount - 1);
    };

    return (
      <div className="flex items-center justify-center mt-3">
        <button
          className="bg-red-400 hover:bg-red-500 text-white font-bold py-2 px-4 rounded-lg hover:scale-110  duration-300"
          onClick={decrement}
        >
          -
        </button>
        <span className="px-4">{count}</span>
        <button
          className="bg-red-400 hover:bg-red-500 text-white font-bold py-2 px-4 rounded-lg hover:scale-11 duration-300"
          onClick={increment}
        >
          +
        </button>
      </div>
    );
  };

  const addcart = async (special) => {
    try {
      setcartItems((prevCartItems) => {
        const newItem = {
          Productid: Items.id,
          Productname: Items.Productname,
          Productprice: Items.Producprice,
          Productcount: count,
          Description: description,
          special:
            special.length > 0
              ? special
                  .find((specialItem) =>
                    specialItem.ValueSetProduct.find(
                      (ValueSetProduct) => ValueSetProduct.id == valuespecial
                    )
                  )
                  ?.ValueSetProduct.find(
                    (ValueSetProduct) => ValueSetProduct.id == valuespecial
                  )?.name || ""
              : "",
          specialprice:
            special.length > 0
              ? parseFloat(
                  special
                    .find((specialItem) =>
                      specialItem.ValueSetProduct.find(
                        (ValueSetProduct) => ValueSetProduct.id == valuespecial
                      )
                    )
                    ?.ValueSetProduct.find(
                      (ValueSetProduct) => ValueSetProduct.id == valuespecial
                    )?.price || 0
                )
              : 0,
        };

        const totalprice =
          parseFloat(Items.Producprice * count) +
          Items.Productcount *
            (special.length > 0
              ? parseFloat(
                  special
                    .find((specialItem) =>
                      specialItem.ValueSetProduct.find(
                        (ValueSetProduct) => ValueSetProduct.id == valuespecial
                      )
                    )
                    ?.ValueSetProduct.find(
                      (ValueSetProduct) => ValueSetProduct.id == valuespecial
                    )?.price || 0
                )
              : 0);

        const totalPriceOfCart = prevCartItems.reduce(
          (accumulator, currentItem) => {
            return accumulator + currentItem.totalprice;
          },
          totalprice
        );
        if (totalPriceOfCart) {
          setprice(totalPriceOfCart);
        } else {
          setprice(price + totalprice);
        }

        localStorage.setItem(
          "cartItems"+id,
          JSON.stringify([...prevCartItems, newItem])
        );
        return [...prevCartItems, { ...newItem, totalprice }];
      });
      setIsOpen(false);
    } catch (error) {
      window.location.reload();
      console.error(error);
    }
  };

  const tocart = async () => {
    Navigate(`/shippingcart?id=${id}`);
  };
  return (
    <>
      <div className="min-h-screen bg-slate-50 w-fullex ">
        <div className="flex flex-col items-center border-b bg-white py-4 sm:flex-row sm:px-10 lg:px-20 xl:px-32">
          <a href="#" className="text-2xl font-bold text-gray-800">
            โต๊ะ {id}
          </a>
          <div className="mt-4 py-2 text-xs sm:mt-0 sm:ml-auto sm:text-base">
            <div className="relative">
              <ul className="relative flex w-full items-center justify-between space-x-2 sm:space-x-4">
                <li className="flex items-center space-x-3 text-left sm:space-x-4">
                  <li className="flex items-center space-x-3 text-left sm:space-x-4">
                    <a
                      className="flex h-6 w-6 items-center justify-center rounded-full bg-gray-600 text-xs font-semibold text-white ring ring-gray-600 ring-offset-2"
                      href="#"
                    >
                      1
                    </a>
                  </li>
                  <span className="font-semibold text-gray-900">Shop</span>
                </li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 text-gray-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
                <li
                  onClick={tocart}
                  className="flex  items-center space-x-3 text-left sm:space-x-4"
                >
                  <a
                    className="flex indicator h-6 w-6 items-center justify-center rounded-full bg-gray-400 text-xs font-semibold text-white"
                    href="#"
                  >
                    {cartItems.length > 0 ? (
                      <>
                        <span className="indicator-item badge badge-secondary">
                          {cartItems.length}
                        </span>{" "}
                      </>
                    ) : (
                      <></>
                    )}
                    2
                  </a>
                  <span className="font-semibold text-gray-500">Shipping Cart</span>
                </li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 text-gray-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
                <li className="flex items-center space-x-3 text-left sm:space-x-4">
                  <a
                    className="flex h-6 w-6 items-center justify-center rounded-full bg-gray-400 text-xs font-semibold text-white"
                    href="#"
                  >
                    3
                  </a>
                  <span className="font-semibold text-gray-500">Payment</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center p-4">
          <div className="w-full">
            <Slider {...settings}>
              {banner.length > 0 ? (
                banner.map((banner, index) => (
                  <div key={index} className="relative h-64 md:h-96 ">
                    <img
                      key={index}
                      src={config.img + banner.Bannerimg}
                      alt={`Banner ${index}`}
                      className="w-full h-full object-cover rounded-lg"
                    />
                  </div>
                ))
              ) : (
                <div className="relative h-64 md:h-96 ">
                  <p>No banners available</p>
                </div>
              )}
            </Slider>
          </div>
        </div>

        <div className="">
          <div className="relative flex min-w-0 mb-6 ml-5 break-words bg-gray-200 dark:bg-gray-950 shadow-soft-xl dark:shadow-soft-dark-xl rounded-2xl bg-clip-border col-span-12 sm:col-span-12 md:col-span-12 xl:col-span-12 overflow-hidden">
            <div className="stats flex stats-vertical lg:stats-horizontal shadow overscroll-x-none w-full">
              {/* {category.length > 0 ? (
                category.map((category, index) => (
                  <div
                    key={index}
                    onClick={(e) => setProduct(category.Product)}
                    className="stat text-center bg-amber-50 w-full text-lg text-black"
                  >
                    <div className="stat-title">{category.Categoryname}</div>
                  </div>
                ))
              ) : (
                <></>
              )} */}
            </div>
          </div>

          <div className="mb-6 m-5">
            <div
              className="hidden opacity-100 transition-opacity duration-150 ease-linear data-[twe-tab-active]:block"
              id="tabs-home01"
              role="tabpanel"
              aria-labelledby="tabs-home-tab01"
              data-twe-tab-active
            >
              <div className="container mx-auto flex items-center justify-center">
                <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">
                  {Product.length > 0 ? (
                    <>
                      {Product.map((product, index) => (
                        <div
                          key={index}
                          className="container mx-auto flex items-center bg-slate-100 rounded-lg"
                        >
                          <div className="image flex justify-center w-32 h-32 mr-4">
                            <img
                              className="ml-10 h-32 w-36 rounded-md border object-cover object-center"
                              src={config.img + product.Productimg}
                              alt="ตัวอย่างรูปภาพ"
                            />
                          </div>
                          <div className="m-4">
                            <div className="flex w-full flex-col px-4 py-4 mt-3">
                              <span className="font-semibold text-lg">
                                {product.Productname}
                              </span>
                              <span className="float-right text-gray-400">
                                {product.Producprice} บาท
                              </span>
                            </div>
                            <div className="ml-32">
                              <button
                                onClick={(e) => openModal(product)}
                                className="bg-red-500 hover:scale-110 hover:bg-red-500 duration-300 text-white font-bold py-2 px-4 rounded-lg text-l"
                              >
                                เพิ่มรายการ
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}

                  <div className="container h-24 flex items-center"></div>
                  <div className="container h-24 flex items-center"></div>
                  <div className="container h-24 flex items-center"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white h-20 w-full flex justify-center fixed bottom-0">
          <div
            onClick={tocart}
            className="btn bg-red-500 mt-4 mb-8 w-64 px-6 py-3 rounded-lg flex justify-between items-center hover:scale-110 hover:bg-red-700 duration-300"
          >
            <h1 className="text-white font-thai">{cartItems.length} รายการ</h1>
            <h1 className="text-white font-thai">ดูคำสั่งซื้อ</h1>
            <h1 className="text-white font-thai">{price} บาท</h1>
          </div>
        </div>
      </div>

      <div>
        <div
          className={`fixed inset-0 flex items-center justify-center z-10 transition-opacity duration-300 ${
            isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
          }`}
        >
          <div
            className="absolute inset-0 bg-gray-900 opacity-75"
            onClick={closeModal}
          ></div>
          <div className="relative bg-white p-8 rounded-lg shadow-lg transform transition-transform duration-300 w-auto">
            <div className="mb-5">
              <span className="font-bold text-xl mb-5">
                {Items.Productname}
              </span>
            </div>
            {special.length > 0 ? (
              <>
                {special.map((special, index) => (
                  <>
                    {" "}
                    <span className="font-bold text-md">{special.name}</span>
                    <div className="flex space-x-2 items-center justify-center ">
                      <span className="loading loading-dots loading-md mr-5 "></span>
                      {special.ValueSetProduct.map((value, index) => (
                        <label
                          key={index}
                          onChange={(e) => setvaluespecial(e.target.value)}
                          className="text-center font-bold transition-opacity hover:opacity-50 ml-5 w-auto place-items-center "
                        >
                          <input
                            type="radio"
                            className="flex items-center m-5 justify-center w-6 h-6 accent-red-500 bg-gray-100 rounded-lg dark:bg-gray-600 transition-colors hover:bg-gray-200 dark:hover:bg-gray-500"
                            name="size"
                            value={value.id}
                          />
                          <span className="ml-0 font-bold">{value.name}</span>
                        </label>
                      ))}

                      <span className="loading loading-dots loading-md ml-10"></span>
                    </div>
                  </>
                ))}
              </>
            ) : (
              <></>
            )}
            <label className="form-control mt-3">
              <textarea
                className="textarea textarea-bordered h-24"
                placeholder="เพิ่มเติม"
                onChange={(e) => setdescription(e.target.value)}
              ></textarea>
            </label>
            <Counter />
            <h1 className="mt-3">
              ตรวจสอบจำนวนและสิ้นค้าที่ต้องการสั่งให้เรียบร้อย
            </h1>
            <div className="flex items-center justify-end mt-3">
              <button
                onClick={(e) => addcart(special)}
                className="bg-red-500 hover:scale-110 hover:bg-red-500 duration-300 text-white font-bold py-2 px-4 rounded-lg text-l"
              >
                เพิ่มสินค้า
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default User1;
