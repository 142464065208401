import axios from 'axios';
import { Helmet } from 'react-helmet';
import { useState, useEffect } from "react";
import config from './config';

function App() {
  const [Nameweb, setNameweb] = useState('');
  const [Imgweb, setImgweb] = useState('');
  const [decweb, setdecweb] = useState('');
  useEffect(() => {
    fetchData();
  }, [])
const fetchData = async() => {
  const res = await axios.get(config.apipath + 'owner/configweb');
  setNameweb(res.data.nameweb);
  setImgweb(res.data.iconweb);
  setdecweb(res.data.description);
};

  return (
    <>
      <Helmet>
      <meta charset="utf-8" />
      <link rel="icon" href={Imgweb}/>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />
      <meta
        name="description"
        content={decweb}
      />
      <link rel="apple-touch-icon" href={config.img + Imgweb}/>
      <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
      <title>{Nameweb}</title>
      </Helmet>
    </>
  );
}

export default App;