
import { useNavigate } from "react-router-dom";
import config from "../config";

import axios from "axios";
import { useReactToPrint } from "react-to-print";
import Dashboard from "./dashboard";
import React from "react";
import { useEffect, useState, useRef } from "react";
import ComponentToPrint from "../Component/ComponentToPrint";
function Orderdashbord() {
  const Navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const id = urlSearchParams.get("id");
  const [order, setorder] = useState([]);
  const [datajob, setdatajob] = useState([]);
  const [Product, setProduct] = useState([]);
  const [Bill, setBill] = useState();

  const [showModal, setShowModal] = useState(false);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const openModal = async (item) => {
    const table = item.tableid;

    const finalData = item.BillsaleDetail.reduce((acc, BillsaleDetailItem) => {
      const foundProduct = Product.find(
        (ProductItem) => BillsaleDetailItem.productid === ProductItem.id
      );
      if (foundProduct) {
        const job = datajob.find((datajob) =>
          datajob.Category.find(
            (category) => category.id === foundProduct.Categoryid
          )
        );

        if (job) {
          const existingData = acc.find((data) => data.id === job.id);
          const newProduct = {
            name: foundProduct.Productname,
            Description: BillsaleDetailItem.Description,
            special: BillsaleDetailItem.special,
            Productcount: BillsaleDetailItem.Productcount,
            Producprice: foundProduct.Producprice,
          };

          if (existingData) {
            existingData.product.push(newProduct);
          } else {
            acc.push({
              id: job.id,
              name: job.Jobname,
              table: table,
              pricetotal: BillsaleDetailItem.special,
              product: [newProduct],
            });
          }
        }
      }
      return acc;
    }, []);

    const finalDataArray = Object.values(finalData);

    setBill(finalDataArray);


    
    setShowModal(!showModal);
  };

  const Modal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    fetchData();
  }, []);
  const payload = async () => {
    try {
      const res = await axios.get(
        config.apipath + "admin/token",
        config.headers()
      );
      if (res.data.payload === undefined) {
        Navigate("/");
      }
    } catch (error) {}
  };
  const fetchData = async () => {
    const res = await axios.get(
      config.apipath + "get/Billsale",
      config.headers()
    );

    const resjob = await axios.get(
      config.apipath + "admin/job",
      config.headers()
    );
    setdatajob(resjob.data);
    const resProduct = await axios.get(config.apipath + "get/product");
    setProduct(resProduct.data);
    res.data.map((order) => {
      order.id == id ? <>{setorder(order)}</> : <></>;
    });
  
  };

  const submitpayment = async (item) => {
    try {
      if (item) {
        const data = {
          value: item,
          order: order,
        };
        const payment = await axios.put(
          config.apipath + "admin/submitpayment",
          data
        );
      }
    } catch (error) {}
  };

  const submitorder = async (item) => {
    try {
      if (item) {
        const data = {
          value: item,
          order: order,
        };
        const payment = await axios.put(
          config.apipath + "admin/submitorder",
          data
        );
      }
    } catch (error) {}
  };

  return (
    <>
      <Dashboard>
        <div className="mt-5 col-span-12 m-2 rounded-lg sm:grid-cols-12 w-full">
          <div className="relative flex flex-col min-w-0 mt-5 mb-6 ml-5 break-words  dark:bg-gray-950 shadow-soft-xl dark:shadow-soft-dark-xl rounded-2xl bg-clip-border border-spacing-6 col-span-12">
            <div className="mt-5 shadow-40 bg-white border-radius-10 h-full w-full shadow-xl rounded-lg">
              <div className="py-8 m-3">
                <div className=" mx-auto px-4">
                  <h1 className="text-2xl font-semibold mb-4 ">
                    คำสั่งซื้อล่าสุด
                    <i className="fa-solid fa-greater-than m-4"></i>
                    ดู
                  </h1>
                  <div className="flex flex-col md:flex-row gap-4">
                    <div className=" w-full ">
                      <div className="relative overflow-x-auto shadow-soft-xl dark:shadow-soft-dark-xl rounded-2xl bg-clip-border border-spacing-6">
                        <table className="w-full text-sm text-left shadow rtl:text-right text-gray-500 dark:text-gray-400 ">
                          <thead className="text-center text-gray-700 uppercase bg-amber-100 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                              <th className="text-left font-semibold px-6 py-3 ">
                                โต๊ะ
                              </th>
                              <th className="text-center font-semibold px-6 py-3 ">
                                รายการอาหาร
                              </th>
                              <th className="font-semibold text-center px-6 py-3 ">
                                รายการเพิ่มเติม
                              </th>
                              <th className="font-semibold text-center px-6 py-3 ">
                                รายละเอียดเพิ่มเติม
                              </th>
                              <th className="font-semibold text-center px-6 py-3 ">
                                เคาน์เตอร์
                              </th>
                              {/* <th className="font-semibold text-center px-6 py-3 ">
                                การทำ
                              </th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {order.BillsaleDetail ? (
                              <>
                                {order.BillsaleDetail.map((BillsaleDetail) => (
                                  <>
                                    {Product.length > 0 ? (
                                      <>
                                        {Product.map((Product) => (
                                          <>
                                            {Product.id ===
                                            BillsaleDetail.productid ? (
                                              <tr className="h-20 bg-amber-50">
                                                <td className="py-4 px-6">
                                                  <div className="flex items-center">
                                                    <span className="font-semibold">
                                                      {order.tableid}
                                                    </span>
                                                  </div>
                                                </td>
                                                <td className="py-4 text-center px-6">
                                                  <>{Product.Productname}</>
                                                </td>
                                                <td className="py-4 text-center px-6">
                                                  {BillsaleDetail.special}
                                                </td>
                                                <td className="py-4 text-center px-6">
                                                  {BillsaleDetail.Description}
                                                </td>
                                                <td className="py-4 text-center px-6">
                                                  {datajob ? (
                                                    <>
                                                      {datajob.map((job) => (
                                                        <>
                                                          {job.Category ? (
                                                            <>
                                                              {job.Category.map(
                                                                (Category) => (
                                                                  <>
                                                                    {Category.id ==
                                                                    Product.Categoryid ? (
                                                                      <>
                                                                        {
                                                                          job.Jobname
                                                                        }
                                                                      </>
                                                                    ) : (
                                                                      <></>
                                                                    )}
                                                                  </>
                                                                )
                                                              )}
                                                            </>
                                                          ) : (
                                                            <></>
                                                          )}
                                                        </>
                                                      ))}
                                                    </>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </td>
                                                {/* <td className="py-4 text-center flex m-4">
                                <i className="fa-regular fa-eye mr-5 bg-green-50 w-1/4 h-1/4 text-green-800 rounded-lg"></i>
                                <i className="fa-solid fa-trash text-red-500 bg-red-50 w-1/4 h-1/4 rounded-lg"></i>
                              </td> */}
                                              </tr>
                                            ) : (
                                              <></>
                                            )}
                                          </>
                                        ))}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                ))}
                              </>
                            ) : (
                              <></>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="md:w-2/4">
                      <div className="bg-white rounded-lg shadow-md p-6">
                        <div className="flex justify-center mb-2">
                          {order.payment ? (
                            <>
                              {order.payment.map((payment) => (
                                <>
                                  {payment.paymentimg === "0" ? (
                                    <> </>
                                  ) : (
                                    <>
                                      <img
                                        src={config.img + payment.paymentimg}
                                      />
                                    </>
                                  )}
                                </>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="flex justify-between mb-2">
                          <span>ช่องทางการจ่ายเงิน</span>
                          <span>
                            {order.payment ? (
                              <>
                                {order.payment.map((payment) => (
                                  <>
                                    {payment.paymentimg !== '0' ? (<>พร้อมเพย์</>):(<>เงินสด</>)}
                                  </>
                                ))}
                              </>
                            ) : (
                              <></>
                            )}
                          </span>
                        </div>
                        <div className="flex justify-between mb-2">
                          <span>ยืนยันการชำระเงิน</span>
                          <select
                            onChange={(e) => submitpayment(e.target.value)}
                            className="select select-accent w-3/6 max-w-xs"
                          >
                            <option value="0">ยังไม่ได้ชำระเงิน</option>
                            <option value="1">ชำระเงินแล้ว</option>
                          </select>
                        </div>
                        <h2 className="text-lg font-semibold mb-4">สรุปยอด</h2>
                        <div className="flex justify-between mb-2">
                          <span>ยอดทั้งหมด</span>
                          <span>{order.pricetotal}</span>
                        </div>
                        <div className="flex justify-between mb-2">
                          <span>ภาษี</span>
                          <span>0.00</span>
                        </div>
                        <div className="flex justify-between mb-2">
                          <span>การส่งของ</span>
                          <span>0.00</span>
                        </div>
                        <hr className="my-2" />
                        <div className="flex justify-between mb-2">
                          <span className="font-semibold">รวมทั้งหมด</span>
                          <span className="font-semibold">
                            {order.pricetotal}
                          </span>
                        </div>
                        <button
                          onClick={(e) => openModal(order)}
                          className="bg-blue-500 text-white py-2 px-4 rounded-lg mt-4 w-full hover:scale-110 hover:bg-purple-500 duration-300"
                        >
                          พิมพ์บิล
                        </button>
                        <div className="flex justify-between m-5">
                          <span>สถานะ</span>
                          <select
                            onChange={(e) => submitorder(e.target.value)}
                            className="select select-error w-3/6 max-w-xs"
                          >
                            <option value="0">ยังไม่ได้ดำเนินการ</option>
                            <option value="1">กำลังดำเนินการ</option>
                            <option value="2">สินค้าพร้อมแล้ว</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="mt-10 shadow-40 bg-white border-radius-10 h-auto shadow-xl col-span-2 m-2 rounded-lg h-auto">
          <div className="container mx-auto flex items-center">
            <div className="image flex justify-center w-24 h-24 mr-4">
              <img
                src="https://cdn.discordapp.com/attachments/1195436273758912572/1215695518580211783/image-removebg-preview_1.png?ex=65fdafae&is=65eb3aae&hm=c686dab5aa52534fc9f520bc7084670bd3aeef60fded9edb0683ba2424f7949b&"
                alt="ตัวอย่างรูปภาพ"
              />
            </div>
            <div className="text">
              <h1 className="mb-4">ชานมไข่มุก</h1>
              <p className="text-sm">ราคา: 50 บาท</p>
            </div>
          </div>
          <hr className="m-5" />
        </div> */}

        <div
          id="authentication-modal"
          className={`${
            showModal ? "fixed" : "hidden"
          } overflow-y-auto overflow-x-hidden inset-0 z-50 flex justify-center items-center`}
        >
          <div className="absolute inset-0 bg-black opacity-50"></div>
          {Bill ? (
            <>
              {Bill.map((Bill) => (
                <>
                  <div className="relative bg-white rounded-lg shadow-lg max-w-md w-full  p-4 ml-20">
                    <div className="flex items-center justify-between pb-4">
                      <button
                        className="w-3/6 h-5/6 bg-green-300 rounded-lg p-1"
                        onClick={handlePrint}
                      >
                        <i className="fa-solid fa-print "></i> พิมพ์บิล
                      </button>
                      <button
                        type="button"
                        className="w-3/6 h-5/6 bg-red-300 rounded-lg p-1 ml-3"
                        onClick={Modal}
                      >
                        ปิด
                      </button>
                    </div>
                    <div className="w-full">
                      <ComponentToPrint
                        data={Bill}
                        ref={componentRef}
                      ></ComponentToPrint>
                    </div>
                  </div>
                </>
              ))}
            </>
          ) : (
            <></>
          )}
        </div>
      </Dashboard>
    </>
  );
}

export default Orderdashbord;
