import Dashboard from "./dashboard";
import icon from "../img/icon-thaiqr.png";
import { useEffect, useState } from "react";
import axios from "axios";
import config from "../config";
export default function Dashboardpromptpay() {
  const [btnedit, setbtnedit] = useState(false);
  const [promptpay, setpromptpay] = useState();
  const [promptpayid, setpromptpayid] = useState();
  useEffect(() => {
    getpromptpay();
  }, [])

  const getpromptpay = async() => {
    try {
      const res = await axios.get(config.apipath + 'owner/promptpay');
      setpromptpay(res.data.Phone);
      setpromptpayid(res.data.id)
    } catch (error) {
      
    }
  }

  const promptpayedit = async() => {
    try {
      if(promptpay && promptpayid) {

        const data = {
          id : promptpayid,
          phone : promptpay
        }
        const editpp = await axios.put(config.apipath + 'owner/editpromptpay', data);
        getpromptpay();
        setbtnedit(false)
      }
      

    } catch (error) {
      
    }
  }

  return (
    <>
      <Dashboard>
        <div className=" col-span-12 text-xs sm:text-xs md:text-lg lg:text-lg">
          {" "}
          การจัดเลขพร้อมเพย์
        </div>
        <div className="mt-10 col-span-12 sm:col-span-12 md:col-span-12 xl:col-span- text-xs sm:text-xs md:text-lg lg:text-lg">
          <div className="w-full lg:h-1/6  md:h-1/6  xl:h-1/4 sm:h-5/6 m-auto bg-sky-800 rounded-xl relative text-white shadow-2xl transition-transform transform sm:col-span-12 md:col-span-12 xl:col-span-12">
            <img
              className="relative object-cover w-full h-full rounded-xl"
              src="https://i.imgur.com/kGkSg1v.png"
            />

            <div className="w-full px-8 absolute top-8">
              <div className="flex justify-between">
                <div>
                  <p className="font-light">เลขพร้อมเพย์</p>
                  {btnedit ? (
                    <div className="flex text-black">
                      <input
                        type="text"
                        value={promptpay}
                        onChange={(e) => setpromptpay(e.target.value)}
                        placeholder="PromptPay Number"
                        className="mt-6 input input-bordered max-w-x "
                      />{" "}
                      <i
                        onClick={promptpayedit}
                        className="fa-solid fa-check m-10"
                      ></i>
                    </div>
                  ) : (
                    <>
                      <p className="mt-6 font-medium tracking-more-wider">
                        {promptpay}
                        <i
                          onClick={(e) => setbtnedit(true)}
                          className="fa-solid fa-pen ml-10"
                        ></i>
                      </p>{" "}
                    </>
                  )}
                </div>

                <img className="w-24 h-24" src={icon} />
              </div>
            </div>
          </div>
        </div>
      </Dashboard>
    </>
  );
}
