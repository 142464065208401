import BarEmployee from "../component/BarEmployee";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import config from "../../config";
import Swal from "sweetalert2";
import Barcategory from "./barcategory";

function Listcategory() {
  const Navigate = useNavigate();
  const [category, setcategory] = useState([]);
  const [job, setjob] = useState([]);
  useEffect(() => {
    payload();
    datacategory();
  }, []);
  const payload = async () => {
    try {
      const res = await axios.get(
        config.apipath + "admin/token",
        config.headers()
      );
      if (res.data.payload === undefined) {
        Navigate('/')
      } 
    } catch (error) {
      
    }
  };


  const datacategory = async () => {
    try {
      const res = await axios.get(config.apipath + "admin/category",config.headers());
      const job = await axios.get(config.apipath + "admin/job",config.headers());
      setcategory(res.data);
      setjob(job.data);
      
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: e.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const del = async (item) => {
    try {
      if (item === "") {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      Swal.fire({
        title: "ยืนยันการลบรายการ",
        text: "กรณีลบรายการไปแล้วจะไม่สามารถเรียกคืนค่าได้อีก ต้องเพิ่มใหม่เท่านั้น",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const del = await axios.delete(
            config.apipath + "admin/deletecategory/" + item.id ,config.headers()
          );
          datacategory();
          if (del.data.message === "success") {
            Swal.fire({
              title: "ลบรายการสำเร็จ",
              text: "Your file has been deleted.",
              icon: "success",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "เกิดข้อผิดพลาด",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }
      });
    } catch {
      Swal.fire({
        icon: "error",
        title: "เกิดข้อผิดพลาด",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const Productlist = async (category) => {
    try {
      if (category.id !== "") {
        const url = "/productlist?id=" + category.id;
        Navigate(url);
      }
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "เกิดข้อผิดพลาด",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const edit = async (category) => {
    try {
      if (category.id !== "") {
        const url = "/editcategory?id=" + category.id;
        
        Navigate(url);
      }
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "เกิดข้อผิดพลาด",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  return (
    <>
      <BarEmployee>
        <div className="mt-5 h-auto col-span-6 m-2 rounded-lg sm:grid-cols-8">
          <Barcategory count={category.length} />
          <div className="mt-5 border-radius-10  rounded-lg">
            <div className="grid gap-x-2 gap-y-10 grid-cols-4 px-5 ml-2 ">
              {category.length > 0 ? (
                <>
                  {category.map((category, index) => (
                    <>
                      
                      <div
                        
                        key={index}
                        className="card w-4-5 bg-base-100 shadow-xl mt-3 hover:bg-orange-100 mb-5 "
                      >
                        <div className="flex justify-end">
                        <details className="dropdown">
                          <summary className="m-1">
                            <svg
                              className="w-5 h-5 inline-block mr-2"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 16 3"
                            >
                              <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z" />
                            </svg>
                          </summary>
                          <ul className="p-2 shadow menu dropdown-content z-[1] bg-base-100 rounded-box w-52">
                          <li onClick={(e) => edit(category)}>
                              <a>แก้ไขหมวดหมู่สินค้า</a>
                            </li>
                            <li onClick={(e) => del(category)}>
                              <a>ลบหมวดหมู่สินค้า</a>
                            </li>
                          </ul>
                        </details>
                      </div>
                      <div onClick={(e) => Productlist(category)}>
                        <figure>
                          <img
                            src={config.img + category.Categoryimg}
                            alt="Shoes"
                            className="rounded-md m-2"
                            style={{ height: "150px", width: "150px" }}
                          />
                        </figure>

                        <h2 className="card-title text-sky-900 justify-center m-3 ">
                          {category.Categoryname}
                        </h2>

                        <div className="card-actions justify-center m-2">
                          <div className="badge badge-outline text-sky-900">
                            {job.length > 0 ? (
                              <>
                                {job.map((job, index) => (
                                  <>
                                    {job.id === category.JobId ? (
                                      <>{job.Jobname}</>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                ))}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>

                          <br />
                        </div></div>
                      </div>
                    </>
                  ))}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </BarEmployee>
    </>
  );
}

export default Listcategory;
