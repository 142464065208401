import { useNavigate } from "react-router-dom";

export default function Barcategory(props) {
  const Navigate = useNavigate();
  return (
        <>
        <div className="grid gap-x-8 gap-y-4 grid-cols-5">
            <button
            onClick={(e) => Navigate("/listcategory")}
             className="bg-orange-100 px-4 py-2 rounded-md text-sm font-medium hover:scale-110 hover:bg-stone-400 duration-300">
              หมวดหมู่
            </button>
            <button
              onClick={(e) => Navigate("/categoryadd")}
              className="bg-orange-100 px-4 py-2 rounded-md text-sm font-medium hover:scale-110 hover:bg-stone-400 duration-300"
            >
              เพิ่มหมวดหมู่
            </button>
            <button
              className="bg-orange-100 px-4 py-2 col-span-3 rounded-md text-sm font-medium hover:scale-110 hover:bg-stone-400 duration-300"
            >
              จำนวนหมวดหมู่ทั้งหมด : {props.count}
            </button>
            
          </div>
        </>
    );
}