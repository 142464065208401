import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Listcategory from "./employee/category/listcategory";
import Narbar from "./employee/component/NavBar";
import Tablelist from "./employee/table/Tablelist";
import Addcategory from "./employee/category/addcategory";
import NewOrder from "./employee/order/Neworder";
import Ordersuccess from "./employee/order/ordersuccess";
import Order from "./employee/order/order";
import Dashboard from "./owner/dashboard";
import DashboardTotal from "./owner/dashboardTotal";
import BarEmployee from "./employee/component/BarEmployee";
import Productlist from "./employee/product/Productlist";
import Productadd from "./employee/product/Productadd";
import Productedit from "./employee/product/Productedit";
import Editcategory from "./employee/category/editcategory";
import Login from "./Login";
import DashbordTable from "./owner/dashbordTable";
import DashbordCategory from "./owner/dashbordCategory";
import DashbordCounter from "./owner/dashbordCounter";
import DashbordProduct from "./owner/dashbordProduct";
import DashbordSpecial from "./owner/dashbordSpecial";
import DashbordBanner from "./owner/dashbordBanner";
import User1 from "./user/homeuser";
import User2 from "./user/homeuser2";
import User3 from "./user/homeuser3";
import User4 from "./user/homeuser4";
import ComponentToPrinttable from "./Component/ComponentToPrinttable";
import Neworderwait from "./employee/order/Neworderwait";
import NeworderSuccess from "./employee/order/NeworderSuccess";
import Orderwait from "./employee/order/orderwait";
import OrderNewdashbord from "./owner/dashbordNeworder";
import Orderdashbord from "./owner/dashbordorder";
import OrderShow from "./owner/dashbordshoworder";
import Dashboardweb from "./owner/dashboardweb";
import Dashboardpromptpay from "./owner/dashboardpromptpay";
import OrderShowEM from "./employee/order/showorder";
import App from "./App";
import DashboardTotalEM from "./employee/dashboardTotal";
import DashbordUser from "./owner/dashbordUser";

const router = createBrowserRouter([
  {
    path: "/ComponentToPrint",
    element: <ComponentToPrinttable />,
  },

  {
    path: "/",
    element: <Login />,
  },

  {
    path: "/listcategory",
    element: <Listcategory />,
  },

  {
    path: "/editcategory",
    element: <Editcategory />,
  },

  {
    path: "/barEmployee",
    element: <BarEmployee />,
  },

  {
    path: "/testgap",
    element: <Narbar />,
  },

  {
    path: "/tablelist",
    element: <Tablelist />,
  },

  // Employee
  {
    path: "/categoryadd",
    element: <Addcategory />,
  },

  {
    path: "/productlist",
    element: <Productlist />,
  },

  {
    path: "/productadd",
    element: <Productadd />,
  },

  {
    path: "/productedit",
    element: <Productedit />,
  },

  {
    path: "/total",
    element: <DashboardTotalEM />,
  },

  // Order

  {
    path: "/neworder",
    element: <NewOrder />,
  },

  {
    path: "/order",
    element: <Order />,
  },

  {
    path: "/Ordersuccess",
    element: <Ordersuccess />,
  },

  {
    path: "/Orderwait",
    element: <Orderwait />,
  },

  {
    path: "/Neworderwait",
    element: <Neworderwait />,
  },

  {
    path: "/NeworderSuccess",
    element: <NeworderSuccess />,
  },

  {
    path: "/ownertest",
    element: <Dashboard />,
  },

  {
    path: "/owner",
    element: <DashboardTotal />,
  },

  {
    path: "/ownertable",
    element: <DashbordTable />,
  },

  {
    path: "/ownercategory",
    element: <DashbordCategory />,
  },

  {
    path: "/ownercounter",
    element: <DashbordCounter />,
  },

  {
    path: "/ownerproduct",
    element: <DashbordProduct />,
  },

  {
    path: "/ownerspecial",
    element: <DashbordSpecial />,
  },

  {
    path: "/ownerbanner",
    element: <DashbordBanner />,
  },

  {
    path: "/ownerneworder",
    element: <OrderNewdashbord />,
  },

  {
    path: "/ownerordernew",
    element: <Orderdashbord />,
  },

  {
    path: "/ownerordershow",
    element: <OrderShow />,
  },

  {
    path: "/ownerweb",
    element: <Dashboardweb />,
  },

  {
    path: "/ownerpromptpay",
    element: <Dashboardpromptpay />,
  },

  {
    path: "/owneruser",
    element: <DashbordUser/>
  },

  {
    path: "/table",
    element: <User1 />,
  },

  {
    path: "/shippingcart",
    element: <User2 />,
  },

  {
    path: "/payment",
    element: <User3 />,
  },
  {
    path: "/wait",
    element: <User4 />,
  },

  {
    path: "/ordershow",
    element: <OrderShowEM />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <reportWebVitals>
    <App />
    <RouterProvider router={router} />
  </reportWebVitals>
);
