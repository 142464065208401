import { useEffect, useState } from "react";
import Dashboard from "./dashboard";
import axios from "axios";
import config from "../config";
import Swal from "sweetalert2";

export default function Dashboardweb() {
  const [id, setid] = useState("");
  const [name, setname] = useState("");
  const [editname, seteditname] = useState(false);
  const [description, setdescription] = useState("");
  const [editdescription, seteditdescription] = useState(false);
  const [icon, seticon] = useState("");
  const [iconfile, seticonfile] = useState("");
  const [editicon, setediticon] = useState(false);

  useEffect(() => {
    getdata();
  }, []);

  const getdata = async () => {
    try {
      const res = await axios.get(config.apipath + "owner/configweb");
      setid(res.data.id);
      setname(res.data.nameweb);
      setdescription(res.data.description);
      seticon(res.data.iconweb);
    } catch (error) {}
  };

  const nameedit = async () => {
    try {
      if (id) {
        if (name) {
          const data = {
            id : id,
            name : name
          }
          const res = await axios.put(config.apipath + "owner/editnameweb", data);
          getdata();
          seteditname(false)
        } else {
          Swal.fire({
            icon: "error",
            title: "กรุณาลองอีกครั้ง",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "กรุณาลองอีกครั้ง",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "กรุณาลองอีกครั้ง",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const descriptionedit = async () => {
    try {
      if (id) {
        if (description) {
          const data = {
            id : id,
            description : description
          }
          const res = await axios.put(config.apipath + "owner/editdescriptionweb", data);
          getdata();
          seteditdescription(false)
        } else {
          Swal.fire({
            icon: "error",
            title: "กรุณาลองอีกครั้ง",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "กรุณาลองอีกครั้ง",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "กรุณาลองอีกครั้ง",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };


  const iconedit = async () => {
    try {
      if (id) {
        if (name) {
          const data = {
            id : id,
          }
          const formData = new FormData();
            formData.append("id", id);
            formData.append("file", iconfile);
            const response = await axios.post(
              config.apipath + "owner/editiconweb",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
          getdata();
          setediticon(false)
        } else {
          Swal.fire({
            icon: "error",
            title: "กรุณาลองอีกครั้ง",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "กรุณาลองอีกครั้ง",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "กรุณาลองอีกครั้ง",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };


  return (
    <>
      <Dashboard>
        <div className=" col-span-12 text-xs sm:text-xs md:text-lg lg:text-lg">
          {" "}
          การจัดเว็บไซต์
        </div>
        <div className="col-span-12 sm:col-span-12 md:col-span-12 xl:col-span- text-xs sm:text-xs md:text-lg lg:text-lg">
          <div className="mockup-browser border bg-base-300 mt-20">
            <div className="mockup-browser-toolbar">
              <div className="input">{name}</div>
            </div>
            <div className="flex px-4 py-5 bg-base-200">
              {editname ? (
                <>
                  <i onClick={nameedit} className="fa-solid fa-check mr-5"></i>

                  <div className="flex ">ชื่อเว็บไซต์ : </div>

                  <div className="flex ml-5">
                    <textarea
                      value={name}
                      onChange={(e) => setname(e.target.value)}
                      placeholder="ชื่อเว็บไซต์"
                      className="textarea textarea-bordered textarea-lg w-full resize"
                    ></textarea>
                  </div>
                </>
              ) : (
                <>
                  <i
                    onClick={(e) => seteditname(true)}
                    className="fa-solid fa-pen mr-5"
                  ></i>
                  <div className="flex ">ชื่อเว็บไซต์ : </div>
                  <div className="flex ml-5">{name}</div>
                </>
              )}
            </div>
            <div className="flex px-4 py-5 bg-base-200">
              {editdescription ? (
                <>
                  <i
                    onClick={descriptionedit}
                    className="fa-solid fa-check mr-5"
                  ></i>

                  <div className="flex ">รายละเอียดเว็บไซต์ : </div>

                  <div className="flex ml-5">
                    <textarea
                      value={description}
                      onChange={(e) => setdescription(e.target.value)}
                      placeholder="รายละเอียดเว็บไซต์"
                      className="textarea textarea-bordered textarea-lg w-full resize"
                    ></textarea>
                  </div>
                </>
              ) : (
                <>
                  <i
                    onClick={(e) => seteditdescription(true)}
                    className="fa-solid fa-pen mr-5"
                  ></i>
                  <div className="flex ">รายละเอียดเว็บไซต์ : </div>
                  <div className="flex ml-5">{description}</div>
                </>
              )}
            </div>

            <div className="flex px-4 py-5 bg-base-200">
              {editicon ? (
                <>
                  <i
                    onClick={iconedit}
                    className="fa-solid fa-check mr-5"
                  ></i>

                  <div className="flex ">รูปเว็ปไซต์ : </div>

                  <div className="flex ml-5">
                  <input onChange={(e) => seticonfile(e.target.files[0])} type="file" className="file-input file-input-bordered file-input-error w-full max-w-xs" />
                  </div>
                </>
              ) : (
                <>
                  <i
                    onClick={(e) => setediticon(true)}
                    className="fa-solid fa-pen mr-5"
                  ></i>
                  <div className="flex ">รูปเว็ปไซต์ : </div>
                  <div className="flex ml-5">
                    <img className="w-32 h-32" src={config.img + icon}></img>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Dashboard>
    </>
  );
}
