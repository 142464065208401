// ImageComponent.js

import React from 'react';

const ImageComponent = (props) => {
  return (
    <div className="container mx-auto">
      <img
        src={props.img}
        alt="Your Image"
        className="w-64 h-64 object-cover"
      />
    </div>
    
  );
};

export default ImageComponent;
