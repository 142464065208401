import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
export default function BarProduct(props) {
  const Navigate = useNavigate();

  const Productadd = async () => {
    try {
      if (props.id !== "") {
        const url = "/productadd?id=" + props.id;
        Navigate(url);
      }
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "เกิดข้อผิดพลาด",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const category = async() => {
    try {
      if(props.id !== undefined){
        const url = "/productlist?id=" + props.id;
        Navigate(url);
      } else {
        Navigate("/listcategory");
      }


    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "เกิดข้อผิดพลาด",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }

  return (
        <>
        <div className="grid gap-x-8 gap-y-4 grid-cols-5">
            <button
            onClick={category}
             className="bg-orange-100 px-4 py-2 col-span-2 rounded-md text-sm font-medium hover:scale-110 hover:bg-stone-400 duration-300">
              สินค้าในหมวดหมู่ {props.name}
            </button>
            <button
              onClick={Productadd}
              className="bg-orange-100 px-4 py-2 rounded-md text-sm font-medium hover:scale-110 hover:bg-stone-400 duration-300"
            >
              เพิ่มสินค้า
            </button> 
            <button
              className="bg-orange-100 px-4 py-2 rounded-md col-span-2  text-sm font-medium hover:scale-110 hover:bg-stone-400 duration-300"
            >
              จำนวนสินค้าในหมวดหมู่ :  {props.count}
            </button> 
          </div>
        </>
    );
}