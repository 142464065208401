import Dashboard from "./dashboard";
import { useEffect, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import config from "../config";
import Swal from "sweetalert2";
import ComponentToPrinttable from "../Component/ComponentToPrinttable";
export default function DashbordTable() {
  const [table, settable] = useState("");
  const [tableqrcode, settableqrcode] = useState("");
  const Navigate = useNavigate();
  const [count, setcount] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [img, setimg] = useState('');
  const [imgqrcode, setimgqrcode] = useState('');
  const componentRef = useRef();
  const [topTable, settopTable] = useState([]);
  useEffect(() => {
    payload();
    data();
  }, []);
  const payload = async () => {
    try {
      const res = await axios.get(
        config.apipath + "admin/token",
        config.headers()
      );
      if (res.data.payload === undefined) {
        Navigate("/");
      }
    } catch (error) {}
  };
  const data = async () => {
    try {
      const res = await axios.get(config.apipath + "owner/tablecountfive", config.headers());
      settable(res.data.table);
      setcount(res.data.tablecount);
      settopTable(res.data.topTablesWithBillsaleCount);
    } catch (e) {}
  };

  const addqrcode = async () => {
    try {
      const { value: valuetable } = await Swal.fire({
        title: "กรอกหมายเลขโต๊ะ",
        input: "text",
        inputLabel: "เลขโต๊ะ",
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return "กรุณากรอกเลขโต๊ะ";
          }
          const intValue = parseInt(value, 10);
      
          // Check if the value is a valid number
          if (isNaN(intValue)) {
            return "ใส่จำนวนที่เป็นตัวเลข";
          }
          // Optional: you can add additional checks if needed, e.g., positive number
          if (intValue <= 0) {
            return "จำนวนต้องมากกว่า 0";
          }
        },
      });

  
      const data = {
        table: valuetable,
        link: config.apipqrcode + valuetable,
      };
      const res = await axios.post(
        config.apipath + "admin/insertQrcodetable",
        data,
        config.headers()
      );
      if (res.data.message === "success") {
        await Swal.fire({
          icon: "success",
          title: "เพิ่มโต๊ะสำเร็จ",
          showConfirmButton: false,
          timer: 1500,
        });
        window.location.href = "/ownertable";
      } 
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: e.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const deltable = async (item) => {
    try {
      Swal.fire({
        title: "ยืนยันการลบ",
        text: "กรณีลบไปแล้วจะไม่สามารถเรียกคืนค่าได้อีก ต้องเพิ่มใหม่เท่านั้น",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res = await axios.get(
            config.apipath + "get/tabledel/" + item.table
          );

          if (res.data !== "") {
            const del = res.data.table;

            const resdel = await axios.delete(
              config.apipath + "admin/delQrcodetable/" + del
            );

            if (resdel.data.message === "success") {
              await Swal.fire({
                icon: "success",
                title: "ลบโต๊ะสำเร็จ",
                showConfirmButton: false,
                timer: 1500,
              });
              data();
            } else {
              Swal.fire({
                icon: "error",
                title: "เกิดข้อผิดพลาด",
                showConfirmButton: false,
                timer: 1500,
              });
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "เกิดข้อผิดพลาด",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }
      });
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: e.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  // const openModal = (table) => {
  //     console.log(table)
  //     if(table.Qrcodetable){
  //     console.log(table)
  //     table.Qrcodetable.map((Qrcodetable) => (
  //       setimg1(Qrcodetable.img)
  //     ))
  //   }
  //   // setimg
  //   settable1(table.table)
  //   setShowModal(!showModal);
  // };


  const openModal = async(table) => {
    const res = await axios.get(config.apipath + "get/table");

    if(table.id){
      res.data.map((tabledata) => (
        table.id == tabledata.id ? (
          tabledata.Qrcodetable.map((Qrcodetable) => (
            setimgqrcode(Qrcodetable.img)
          ))
        ) : (<></>)
      ))
    }
    // // setimg
    settableqrcode(table.table)
    setShowModal(!showModal);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <Dashboard>
        <div className=" col-span-12 text-xs sm:text-xs md:text-lg lg:text-lg">
          {" "}
          การจัดการโต๊ะ
        </div>
        <div className="col-span-12 sm:col-span-12 md:col-span-12 xl:col-span-6 text-xs sm:text-xs md:text-lg lg:text-lg">
          <div className="relative flex flex-col min-w-0 mt-5 mb-6 ml-5 break-words bg-gray-100 dark:bg-gray-950 shadow-soft-xl dark:shadow-soft-dark-xl rounded-2xl bg-clip-border col-span-12 sm:col-span-12 md:col-span-12 xl:col-span-7">
            <div className="stats stats-vertical lg:stats-horizontal shadow grid grid-cols-1">
              <div className="stat col-span-1 text-center">
                <div className="stat-title">จำนวนโต๊ะ</div>
                <div className="stat-value">{count}</div>
              </div>
            </div>
          </div>
          <div className="relative flex flex-col min-w-0 mt-5 mb-6 ml-5 break-words  dark:bg-gray-950 shadow-soft-xl dark:shadow-soft-dark-xl rounded-2xl bg-clip-border border-spacing-6 col-span-5">
            <div className="ml-10">
              {" "}
              <i className="fa-sharp fa-solid fa-chair mr-2"></i>
              โต๊ะทั้งหมด
              <button
                onClick={addqrcode}
                className="btn btn-square btn-outline btn-accent col-span-1 w-1/6 h-auto ml-52 m-5"
              >
                <i className="fa-sharp fa-solid fa-plus"></i>
              </button>
            </div>
            <div className="relative overflow-x-auto shadow-soft-xl dark:shadow-soft-dark-xl rounded-2xl bg-clip-border border-spacing-6">
              <table className="w-full text-sm text-left shadow rtl:text-right text-gray-500 dark:text-gray-400 ">
                <thead className="text-center text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3 rounded-s-lg bg-amber-50">
                      เลขโต๊ะ
                    </th>
                    <th scope="col" className="px-6 py-3 bg-orange-50">
                      การสั่งซื้อ
                    </th>
                    <th scope="col" className="px-6 py-3 bg-red-50">
                      ตั้งค่า
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {table ? (
                    <>
                      {table.map((table, index) => (
                        <>
                          <tr
                           
                            key={index}
                            onClick={(e) => openModal(table)}
                            className="bg-white dark:bg-gray-800 text-center"
                          >
                            <th
                            // onClick={(e) => openModal(table)}
                              scope="row"
                              className="px-6 py-4 bg-amber-50 text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {table.table}
                            </th>
                            <td className="px-6 py-4 bg-orange-50">
                              {table.BillsaleCount}
                            </td>
                            <td className="px-6 py-4 bg-red-50 grid grid-cols-2">
                              <button
                                onClick={(e) => deltable(table)}
                                className="btn btn-circle btn-outline btn-error col-span-2 w-auto h-auto mr-2"
                              >
                                <i className="fa-sharp fa-solid fa-trash"></i>
                              </button>
                              {/* <button className="btn btn-square btn-outline btn-info col-span-1 w-auto h-auto ">
                                <i className="fa-sharp fa-solid fa-screwdriver-wrench"></i>
                              </button> */}
                            </td>
                          </tr>
                        </>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="col-span-12 sm:col-span-12 md:col-span-12 xl:col-span-6 text-xs sm:text-xs md:text-lg lg:text-lg">
          <div className="ml-10 ">
            {" "}
            <i className="fa-solid fa-medal mr-2"></i> 5
            ลำดับโต๊ะที่มีผู้ใช้งานมากที่สุด
          </div>
          <div className="relative flex flex-col min-w-0 mt-5 mb-6 ml-5 break-words bg-gray-100 dark:bg-gray-950 shadow-soft-xl dark:shadow-soft-dark-xl rounded-2xl bg-clip-border border-spacing-6 col-span-6">
            <div className="relative overflow-x-auto shadow-soft-xl dark:shadow-soft-dark-xl rounded-2xl bg-clip-border border-spacing-6">
              <table className="w-full text-sm text-left shadow rtl:text-right text-gray-500 dark:text-gray-400 ">
                <thead className="text-center text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3 rounded-s-lg bg-amber-50">
                      เลขโต๊ะ
                    </th>
                    <th scope="col" className="px-6 py-3 bg-orange-50">
                      จำนวนการใช้งาน
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {topTable ? (
                    <>
                      {topTable.map((top, index) => (
                        <>
                          <tr
                          
                            key={index}
                            className="bg-white dark:bg-gray-800 text-center"
                          >
                            <th
                              scope="row"
                              className="px-6 py-4 bg-amber-50 text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {top.table}
                            </th>
                            <td className="px-6 py-4 bg-orange-50">
                              {top.BillsaleCount}
                            </td>
                          </tr>
                        </>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div
          id="authentication-modal"
          className={`${
            showModal ? "fixed" : "hidden"
          } overflow-y-auto overflow-x-hidden inset-0 z-50 flex justify-center items-center`}
        >
          <div className="absolute inset-0 bg-black opacity-50"></div>

          <div className="relative bg-white rounded-lg shadow-lg max-w-md w-full  p-4 ml-20">
            <div className="flex items-center justify-between pb-4">
              <button
                className="w-3/6 h-5/6 bg-green-300 rounded-lg p-1"
                onClick={handlePrint}
              >
                <i className="fa-solid fa-print "></i> พิมพ์บิล
              </button>
              <button
                type="button"
                className="w-3/6 h-5/6 bg-red-300 rounded-lg p-1 ml-3"
                // onClick={openModal}
              >
                ปิด
              </button>
            </div>
            <div className="w-full">
              {/* <ComponentToPrinttable  ref={componentRef} table={table} img={img}> </ComponentToPrinttable> */}
            </div>
          </div>
        </div>

        <div
          id="authentication-modal"
          className={`${
            showModal ? "fixed" : "hidden"
          } overflow-y-auto overflow-x-hidden inset-0 z-50 flex justify-center items-center`}
        >
          <div className="absolute inset-0 bg-black opacity-50"></div>

          <div className="relative bg-white rounded-lg shadow-lg max-w-md w-full  p-4 ml-20">
            <div className="flex items-center justify-between pb-4">
              <button
                className="w-3/6 h-5/6 bg-green-300 rounded-lg p-1"
                onClick={handlePrint}
              >
                <i className="fa-solid fa-print "></i> พิมพ์บิล
              </button>
              <button
                type="button"
                className="w-3/6 h-5/6 bg-red-300 rounded-lg p-1 ml-3"
                onClick={openModal}
              >
                ปิด
              </button>
            </div>
            <div className="w-full">
              <ComponentToPrinttable  ref={componentRef} table={tableqrcode} img={imgqrcode}> </ComponentToPrinttable>
            </div>
          </div>
        </div>
      </Dashboard>
    </>
  );
}
