import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../config";

function Dashboard(props) {
  const Navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const closeSidebar = () => {
    setIsOpen(false);
  };
  return (
    <>
      <button
        onClick={toggleSidebar}
        aria-controls="default-sidebar"
        type="button"
        className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          ></path>
        </svg>
      </button>
      {isOpen && (
        <div className="fixed inset-0 z-30" onClick={closeSidebar}></div>
      )}
      <aside
        id="default-sidebar"
        className={`fixed top-0 left-0 z-40 w-64 h-screen transition-transform ${
          isOpen ? "" : "-translate-x-full sm:translate-x-0"
        }`}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
          <ul className="space-y-2 font-medium menu bg-base-200 rounded-box h-full w-full">
            <li onClick={(e) => Navigate("/owner")}>
              <span className="ms-3">
                <i className="fa-solid fa-chart-column"></i>ผลสรุปยอด
              </span>
            </li>
            <li>
              <div className="bg-gray-200 m-2">
                <i className="fa-regular fa-screwdriver-wrench"></i>
                ตั้งค่าข้อมูลระบบ
              </div>
              <ul>
                <li onClick={(e) => Navigate("/ownertable")}>
                  <span className="ms-3">
                    {" "}
                    <i className="fa-solid fa-barcode"></i>โต๊ะ
                  </span>
                </li>
                <li>
                  <span
                    onClick={(e) => Navigate("/ownercounter")}
                    className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap"
                  >
                    <i className="fa-solid fa-shop"></i> เคาน์เตอร์
                  </span>
                </li>
                <li>
                  <span
                    onClick={(e) => Navigate("/ownercategory")}
                    className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap"
                  >
                    <i className="fa-solid fa-cube"></i> หมวดหมู่
                  </span>
                </li>
                <li>
                  <span
                    onClick={(e) => Navigate("/ownerproduct")}
                    className="flex-1 ms-3 whitespace-nowrap"
                  >
                    <i className="fa-solid fa-cart-shopping"></i>สินค้า
                  </span>
                </li>
                <li>
                  <span
                    onClick={(e) => Navigate("/ownerspecial")}
                    className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap"
                  >
                    <i className="fa-solid fa-gift"></i> รายการเพิ่มเติม
                  </span>
                </li>
                <li>
                  <span
                    onClick={(e) => Navigate("/ownerbanner")}
                    className="flex-1 ms-3 whitespace-nowrap"
                  >
                    <i className="fa-solid fa-ticket-simple"></i> รูปโปรโมชั่น
                  </span>
                </li>
              </ul>
            </li>
            <li>
              <div className="bg-gray-200 m-2">
                <i className="fa-regular fa-file-lines"></i>คำสั่งซื้อ
              </div>
              <ul>
                <li>
                  <span
                    onClick={(e) => Navigate("/ownerneworder")}
                    className="flex-1 ms-3 whitespace-nowrap"
                  >
                    <i className="fa-solid fa-cart-plus"></i>คำสั่งซื้อ
                  </span>
                </li>
                <li>
                  <span
                    onClick={(e) => Navigate("/ownerordershow")}
                    className="flex-1 ms-3 whitespace-nowrap"
                  >
                    <i className="fa-solid fa-store"></i>ประวัติรายการขาย
                  </span>
                </li>
              </ul>
            </li>
            <li>
              <div className="bg-gray-200 m-2">
                <i className="fa-regular fa-screwdriver-wrench"></i>
                ตั้งค่า
              </div>
              <ul>
                <li>
                  <span
                    onClick={(e) => Navigate("/ownerweb")}
                    className="flex-1 ms-3 whitespace-nowrap"
                  >
                    <i className="fa-solid fa-code"></i>เว็บไซต์
                  </span>
                </li>
                <li>
                  <span
                    onClick={(e) => Navigate("/ownerpromptpay")}
                    className="flex-1 ms-3 whitespace-nowrap"
                  >
                    <i className="fa-solid fa-coins"></i>เลขบัญชี
                  </span>
                </li>
              </ul>
            </li>
            <li>
              <div className="bg-gray-200 m-2">
                <i className="fa-solid fa-circle-user"></i>
                ผู้ใช้
              </div>
              <ul>
                <li>
                  <span onClick={(e) => Navigate("/owneruser")} className="flex-1 ms-3 whitespace-nowrap">
                    <i className="fa-solid fa-address-book"></i>พนักงาน
                  </span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </aside>

      <div className="p-4 sm:ml-64 grid grid-cols-12">{props.children}</div>
    </>
  );
}

export default Dashboard;
