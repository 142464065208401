import Dashboard from "./dashboard";
import { useEffect, useState } from "react";
import axios from "axios";
import config from "../config";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
export default function DashbordUser() {
  const Navigate = useNavigate();

  const [button, setbutton] = useState(false);
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [passwordconfirm, setpasswordconfirm] = useState("");
  const [role, setrole] = useState("");
  const [btn, setbtn] = useState("");
  const [user, setuser] = useState([]);

  useEffect(() => {
    payload();
    data();
  }, []);

  const payload = async () => {
    try {
      const res = await axios.get(
        config.apipath + "admin/token",
        config.headers()
      );
      if (res.data.payload === undefined) {
        Navigate("/");
      }
    } catch (error) {}
  };
  const data = async () => {
    try {
      const res = await axios.get(
        config.apipath + "admin/user",
        config.headers()
      );
      
      setuser(res.data);
    } catch (e) {}
  };

  const add = async () => {
    try {
      setbutton(true);
      setbtn("add");
    } catch (error) {
      
    }
  };

  const adduser = async () => {
    try {
      if (email !== "" || password !== "" || passwordconfirm !== "") {
        if (email !== "") {
          if (password !== "") {
            if (passwordconfirm !== "") {
              if (role !== "") {
                const data = {
                  email: email,
                  password: password,
                  passwordconfirm: passwordconfirm,
                  role: role,
                };
                const res = await axios.post(
                  config.apipath + "admin/insertuser",
                  data
                );
                // Swal.fire({
                //   title: res.data.message,
                //   showConfirmButton: false,
                //   timer: 1500,
                // });
                data();
              } else {
                Swal.fire({
                  icon: "info",
                  title: "role ยังไม่ได้กรอก",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            } else {
              Swal.fire({
                icon: "info",
                title: "passwordconfirm ยังไม่ได้กรอก",
                showConfirmButton: false,
                timer: 1500,
              });
            }
          } else {
            Swal.fire({
              icon: "info",
              title: "password ยังไม่ได้กรอก",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } else {
          Swal.fire({
            icon: "info",
            title: "email ยังไม่ได้กรอก",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        Swal.fire({
          icon: "info",
          title: "กรอกข้อมูลให้ครบ",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      data();
      // Swal.fire({
      //   icon: "error",
      //   title: "เกิดข้อผิดพลาด",
      //   showConfirmButton: false,
      //   timer: 1500,
      // });
    }
  };

  const del = async (user) => {
    try {
      console.log(user);
      const res = await axios.post(config.apipath + "admin/deluser", user);
      Swal.fire({
        title: res.data.message,
        showConfirmButton: false,
        timer: 1500,
      });
      data();
    } catch (error) {
      
    }
  };

  return (
    <>
      <Dashboard>
        <div className=" col-span-12 text-xs sm:text-xs md:text-lg lg:text-lg">
          {" "}
          การจัดการพนักงาน
        </div>
        <div className="col-span-12 sm:col-span-12 md:col-span-12 xl:col-span-12 text-xs sm:text-xs md:text-lg lg:text-lg">
          <div className="relative flex flex-col min-w-0 mt-5 mb-6 ml-5 break-words bg-gray-100 dark:bg-gray-950 shadow-soft-xl dark:shadow-soft-dark-xl rounded-2xl bg-clip-border col-span-12 sm:col-span-12 md:col-span-12 xl:col-span-12">
            <div className="stats stats-vertical lg:stats-horizontal shadow grid grid-cols-1">
              <div className="stat col-span-1 text-center">
                <div className="stat-title">จำนวนพนักงาน</div>
                <div className="stat-value">{user.length}</div>
              </div>
            </div>
          </div>

          {button ? (
            <div className="animate-fadeIn r">
              <div className="elative grid grid-cols-12 min-w-0 mt-5 mb-6 ml-5 break-words bg-gray-100 dark:bg-gray-950 shadow-soft-xl dark:shadow-soft-dark-xl rounded-2xl bg-clip-border col-span-12 sm:col-span-12 md:col-span-12 xl:col-span-12">
                <div className="stats stats-vertical shadow col-span-12">
                  <div className="stat col-span-6 sm:col-span-12 md:col-span-12 lg:col-span-12  xl:col-span-4  text-center">
                    <label className="input input-bordered input-accent flex items-center gap-2 w-full ">
                      Email
                      <input
                        type="text"
                        className="grow"
                        placeholder="Email"
                        onChange={(e) => setemail(e.target.value)}
                      />
                    </label>
                  </div>

                  <div className="stat col-span-6 sm:col-span-12 md:col-span-12 lg:col-span-12  xl:col-span-4  text-center">
                    <label className="input input-bordered input-accent flex items-center gap-2 w-full ">
                      Password
                      <input
                        type="text"
                        className="grow"
                        placeholder="Password"
                        onChange={(e) => setpassword(e.target.value)}
                      />
                    </label>
                  </div>

                  <div className="stat col-span-6 sm:col-span-12 md:col-span-12 lg:col-span-12  xl:col-span-4 text-center">
                    <label className="input input-bordered input-accent flex items-center gap-2 w-full ">
                      Passwordconfirm
                      <input
                        type="text"
                        className="grow"
                        placeholder="Passwordconfirm"
                        onChange={(e) => setpasswordconfirm(e.target.value)}
                      />
                    </label>
                  </div>

                  <div className="stat col-span-6 sm:col-span-12 md:col-span-12 lg:col-span-12  xl:col-span-4 text-center">
                    <select
                      onChange={(e) => setrole(e.target.value)}
                      className="select select-warning w-full max-w-xs"
                    >
                      <option>เลือก Role</option>

                      <option value="1">พนักงาน</option>
                      <option value="2">เจ้าของกิจการ</option>
                    </select>
                  </div>

                  <div className="stat col-span-6 sm:col-span-12 md:col-span-12 lg:col-span-12  xl:col-span-4 text-center">
                    <button
                      onClick={adduser}
                      className="btn btn-square btn-outline bg-accent flex items-center gap-2 w-full "
                    >
                      เพิ่ม
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className="relative flex flex-col min-w-0 mt-5 mb-6 ml-5 break-words  dark:bg-gray-950 shadow-soft-xl dark:shadow-soft-dark-xl rounded-2xl bg-clip-border border-spacing-6 col-span-12">
            <div className="ml-1 col-span-10 ">
              {" "}
              <i className="fa-sharp fa-solid fa-chair mr-2"></i>
              พนักงาน
              <button
                onClick={add}
                className="btn btn-square btn-outline btn-accent col-span-1 w-1/6 h-auto m-5"
              >
                <i className="fa-sharp fa-solid fa-plus"></i>
              </button>
            </div>
            <div className="relative overflow-x-auto shadow-soft-xl dark:shadow-soft-dark-xl rounded-2xl bg-clip-border border-spacing-6">
              <table className="w-full text-sm text-left shadow rtl:text-right text-gray-500 dark:text-gray-400 ">
                <thead className="text-center text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3 rounded-s-lg bg-yellow-50">
                      Email
                    </th>
                    <th scope="col" className="px-6 py-3 bg-orange-50">
                      Role
                    </th>
                    <th scope="col" className="px-6 py-3 bg-red-50">
                      ตั้งค่า
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {user ? (
                    <>
                      {user.map((user, index) => (
                        <>
                          <tr
                            key={index}
                            className="bg-white dark:bg-gray-800 text-center"
                          >
                            <th
                              scope="row"
                              className="px-6 py-4 bg-yellow-50 text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {user.email}
                            </th>
                            <td className="px-6 py-4 bg-orange-50 ">
                              {user.role === 1 ? (
                                <>พนักงาน</>
                              ) : (
                                <>เจ้าของกิจการ</>
                              )}
                            </td>
                            <td className="px-6 py-4 bg-red-50 ">
                              <div className="flex">
                                <button
                                  onClick={(e) => del(user)}
                                  className="btn btn-circle btn-outline btn-error w-2/4 h-2/4 mr-2"
                                >
                                  <i className="fa-sharp fa-solid fa-trash"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        </>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Dashboard>
    </>
  );
}
