import { useNavigate } from "react-router-dom";
import NavBar from "./NavBar"; // Import NavBar component here
import { useEffect, useState } from "react";

export default function BarEmployee(props) {
  const Navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const closeSidebar = () => {
    setIsOpen(false);
  };
  return (
    <>
      {" "}
      <div className="bg-neutral-50 h-screen">
        <button
          onClick={toggleSidebar}
          aria-controls="default-sidebar"
          type="button"
          className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        >
          <span className="sr-only">Open sidebar</span>
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clipRule="evenodd"
              fillRule="evenodd"
              d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
            ></path>
          </svg>
        </button>
        {isOpen && (
          <div className="fixed inset-0 z-30" onClick={closeSidebar}></div>
        )}
        <aside
          id="default-sidebar"
          className={`fixed top-0 left-0 z-40 w-64 h-screen transition-transform ${
            isOpen ? "" : "-translate-x-full sm:translate-x-0"
          }`}
          aria-label="Sidebar"
        >
          <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
            <ul className="space-y-2 font-medium menu bg-base-200 rounded-box h-full w-full">
             
              <li>
                <div className="bg-gray-200 m-2">
                  <i className="fa-regular fa-screwdriver-wrench"></i>
                  พนักงาน
                </div>
                <ul>
                <li onClick={(e) => Navigate("/total")}>
                    <span className="ms-3">
                      {" "}
                      <i className="fa-solid fa-wave-square"></i>สรุป
                    </span>
                  </li>
                  <li onClick={(e) => Navigate("/tablelist")}>
                    <span className="ms-3">
                      {" "}
                      <i className="fa-solid fa-barcode"></i>โต๊ะ
                    </span>
                  </li>
                  
                  <li>
                    <span
                       onClick={(e) => Navigate("/listcategory")}
                      className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap"
                    >
                      <i className="fa-solid fa-cube"></i> หมวดหมู่, สินค้า
                    </span>
                  </li>
                  <li>
                    <span
                      onClick={(e) => Navigate("/neworder")}
                      className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap"
                    >
                      <i className="fa-solid fa-gift"></i> คำสั่งซื้อ
                    </span>
                  </li>
                  
                </ul>
              </li>
            </ul>
          </div>
        </aside>
        <div className=" sm:ml-64 grid-cols-8">{props.children}</div>
      </div>
    </>
  );
}
