import Dashboard from "./dashboard";
import { useEffect, useState } from "react";
import axios from "axios";
import config from "../config";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
export default function DashbordSpecial() {
  const Navigate = useNavigate();

  const [special, setspecial] = useState("");
  const [button, setbutton] = useState("");
  const [namespecial, setnamespecial] = useState("");
  const [name, setname] = useState("");
  const [price, setprice] = useState("");
  const [index, setindex] = useState("");
  const [id, setid] = useState("");
  const [category, setcategory] = useState("");
  const [categoryid, setcategoryid] = useState("");
  const [dataproduct, setdataproduct] = useState("");
  const [dataproductar, setdataproductar] = useState("");
  const [dataproductid, setdataproductid] = useState("");
  const [specialadd, setspecialadd] = useState([]);

  useEffect(() => {
    payload();
    getdata();
  }, []);

  const payload = async () => {
    try {
      const res = await axios.get(
        config.apipath + "admin/token",
        config.headers()
      );
      if (res.data.payload === undefined) {
        Navigate("/");
      }
    } catch (error) {}
  };
  const getdata = async () => {
    try {
      const resspecial = await axios.get(
        config.apipath + "admin/setproductlist"
      );
      setspecial(resspecial.data);
      const rescategory = await axios.get(
        config.apipath + "admin/category",
        config.headers()
      );
      setcategory(rescategory.data);
      const resproduct = await axios.get(
        config.apipath + "admin/productlist",
        config.headers()
      );
      setdataproduct(resproduct.data);
    } catch (e) {}
  };

  const adds = async () => {
    try {
      if (namespecial.length !== 0) {
        if (specialadd.length !== 0) {
          const data = {
            name: namespecial,
            value: specialadd,
          };
          const res = await axios.post(
            config.apipath + "admin/setproduct",
            data
          );
          if (res.data.message === "success") {
            Swal.fire({
              icon: "success",
              title: "เพิ่มสำเร็จ",
              showConfirmButton: false,
              heightAuto: false,
              timer: 1500,
            });
            cleardata();
          } else {
            Swal.fire({
              icon: "error",
              title: res.data.message,
              showConfirmButton: false,
              heightAuto: false,
              timer: 1500,
            });
          }
        } else {
          Swal.fire({
            icon: "info",
            title: "กรอกข้อมูล",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        Swal.fire({
          icon: "info",
          title: "กรอกข้อมูล",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {}
  };

  const del = async (item) => {
    try {
      if (item === "") {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      Swal.fire({
        title: "ยืนยันการลบรายการ",
        text: "กรณีลบรายการไปแล้วจะไม่สามารถเรียกคืนค่าได้อีก ต้องเพิ่มใหม่เท่านั้น",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res = await axios.delete(
            config.apipath + "admin/deletesetproduct/" + item.id
          );
          if (res.data.message === "success") {
            Swal.fire({
              icon: "success",
              title: "ลบสำเร็จ",
              showConfirmButton: false,
              heightAuto: false,
              timer: 1500,
            });
            getdata();
          } else {
            Swal.fire({
              icon: "error",
              title: res.data.message,
              showConfirmButton: false,
              heightAuto: false,
              timer: 1500,
            });
          }
        }
      });
    } catch (error) {}
  };

  const cleardata = async () => {
    setnamespecial("");
    setname("");
    setprice("");
    setspecialadd([]);
    setdataproductid("");
    setdataproductar("");
    setdataproduct("");
    setcategoryid("");
    setcategory("");
    setid("");
    setindex("");
    setbutton("");
    setspecial("");
    getdata();
  };
  const add = async () => {
    try {
      setspecialadd([]);
      if (button !== "add") {
        await setbutton("add");
      } else {
        await setbutton("");
      }
    } catch (error) {}
  };

  const adddata = async () => {
    try {
      await setspecialadd([
        ...specialadd,
        { namevalue: name, pricevalue: price },
      ]);
    } catch (error) {}
  };

  const handleRemoveItem = async (index, special, item) => {
    try {
      if (special === "") {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      Swal.fire({
        title: "ยืนยันการลบรายการ",
        text: "กรณีลบรายการไปแล้วจะไม่สามารถเรียกคืนค่าได้อีก ต้องเพิ่มใหม่เท่านั้น",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res = await axios.delete(
            config.apipath + "admin/deletevalueproduct/" + special.id
          );
          if (res.data.message === "success") {
            Swal.fire({
              icon: "success",
              title: "ลบสำเร็จ",
              showConfirmButton: false,
              heightAuto: false,
              timer: 1500,
            });
            setspecialadd((specialadd) => {
              return specialadd.filter((item, i) => i !== index);
            });
            getdata();
          } else {
            setspecialadd((specialadd) => {
              return specialadd.filter((item, i) => i !== index);
            });
          }
        }
      });
    } catch (error) {}
  };

  const remove = (index) => {
    setspecialadd((specialadd) => {
      return specialadd.filter((item, i) => i !== index);
    });
  };

  const edit = async (item) => {
    try {
      if (button !== "edit") {
        setbutton("edit");
      } else {
        setbutton("");
      }
      setnamespecial(item.name);
      setid(item.id);
      setname("");
      setprice("");
      setspecialadd(item.ValueSetProduct);
    } catch (error) {}
  };

  const editvalue = (item, index) => {
    try {
      setname(item.name);
      setprice(item.price);
      setindex(index);
    } catch (error) {}
  };

  const editvaluearray = async () => {
    try {
      if (index !== "") {
        setspecialadd((specialadd) => {
          return specialadd.map((item, i) => {
            if (i === index) {
              // แก้ไขข้อมูลในอาร์เรย์ที่ดัชนี `index` ตามที่ต้องการ
              // ในตัวอย่างนี้เราจะเปลี่ยนชื่อสินค้าในอ็อบเจกต์ `Product` ในอาร์เรย์ `specialadd` ที่ดัชนี `index`
              item.name = name;
              item.price = price;
            }
            setindex("");
            return item;
          });
        });
      } else {

        await setspecialadd([...specialadd, { name: name, price: price }]);
        setindex("");
      }
    } catch (error) {}
  };

  const editvaluesubmit = async () => {
    try {
      const data = {
        id: id,
        name: namespecial,
        value: specialadd,
      };
      const res = await axios.post(
        config.apipath + "admin/updatesetproduct",
        data
      );
      if (res.data.message === "success") {
        Swal.fire({
          icon: "success",
          title: "เพิ่มสำเร็จ",
          showConfirmButton: false,
          heightAuto: false,
          timer: 1500,
        });
        cleardata();
      } else {
        Swal.fire({
          icon: "error",
          title: res.data.message,
          showConfirmButton: false,
          heightAuto: false,
          timer: 1500,
        });
      }
    } catch (error) {}
  };

  const product = (item) => {
    if (button !== "product") {
      setbutton("product");
    } else {
      setbutton("");
    }
    setid(item.id);
    setnamespecial(item.name);
    setdataproductar(item.special);
  };

  const adddataproduct = async () => {
    try {
      await setdataproductar([...dataproductar, { id: dataproductid }]);
    } catch (error) {}
  };

  const removeprodocr = (index) => {
    setdataproductar((dataproductar) => {
      return dataproductar.filter((item, i) => i !== index);
    });
  };

  const adddataproductsubmit = async () => {
    try {
      if (id !== "") {
        if (dataproductar !== "") {
          const data = {
            id: id,
            idproduct: dataproductar,
          };
          const res = await axios.post(
            config.apipath + "admin/addproductset",
            data
          );
          if (res.data.message === "success") {
            Swal.fire({
              icon: "success",
              title: "เพิ่มสำเร็จ",
              showConfirmButton: false,
              heightAuto: false,
              timer: 1500,
            });
            cleardata();
          } else {
            Swal.fire({
              icon: "error",
              title: res.data.message,
              showConfirmButton: false,
              heightAuto: false,
              timer: 1500,
            });
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "เกิดข้อผิดพลาด1",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {}
  };

  const delPro = async (index, dataproductar) => {
    try {
      if (special === "") {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      Swal.fire({
        title: "ยืนยันการลบรายการ",
        text: "กรณีลบรายการไปแล้วจะไม่สามารถเรียกคืนค่าได้อีก ต้องเพิ่มใหม่เท่านั้น",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res = await axios.delete(
            config.apipath + "admin/deleteproductset/" + dataproductar.id
          );
          if (res.data.message === "success") {
            Swal.fire({
              icon: "success",
              title: "ลบสำเร็จ",
              showConfirmButton: false,
              heightAuto: false,
              timer: 1500,
            });
            setdataproductar((dataproductar) => {
              return dataproductar.filter((item, i) => i !== index);
            });
            getdata();
          } else {
            setdataproductar((dataproductar) => {
              return dataproductar.filter((item, i) => i !== index);
            });
          }
        }
      });
    } catch (error) {}
  };

  return (
    <>
      <Dashboard>
        <div className=" col-span-12 text-xs sm:text-xs md:text-lg lg:text-lg">
          {" "}
          การจัดการรายการเพิ่มเติม
        </div>
        <div className="col-span-12 sm:col-span-12 md:col-span-12 xl:col-span-12 text-xs sm:text-xs md:text-lg lg:text-lg">
          <div className="relative flex flex-col min-w-0 mt-5 mb-6 ml-5 break-words bg-gray-100 dark:bg-gray-950 shadow-soft-xl dark:shadow-soft-dark-xl rounded-2xl bg-clip-border col-span-12 sm:col-span-12 md:col-span-12 xl:col-span-12">
            <div className="stats stats-vertical lg:stats-horizontal shadow grid grid-cols-1">
              <div className="stat col-span-1 text-center">
                <div className="stat-title">จำนวนสินค้า</div>
                <div className="stat-value">{special.length}</div>
              </div>
            </div>
          </div>

          {button === "add" ? (
            <div className="animate-fadeIn r">
              <div className="elative grid grid-cols-12 min-w-0 mt-5 mb-6 ml-5 break-words bg-gray-100 dark:bg-gray-950 shadow-soft-xl dark:shadow-soft-dark-xl rounded-2xl bg-clip-border col-span-12 sm:col-span-12 md:col-span-12 xl:col-span-12">
                <div className="stats stats-vertical shadow col-span-12">
                  {specialadd ? (
                    <>
                      {" "}
                      <div className="ml-5 text-lg">
                        <button
                          onClick={adds}
                          className="btn btn-square btn-outline bg-red-50 col-span-9 flex items-center gap-2 w-full m-5 ml-10"
                        >
                          ยืนยันการเพิ่ม : {namespecial}
                        </button>
                      </div>
                      <table className="w-full text-sm col-span-12 shadow rtl:text-right text-gray-500 dark:text-gray-400 ">
                        <thead className="text-center text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                          <tr>
                            <th
                              scope="col"
                              className="px-6 py-3 rounded-s-lg bg-yellow-50"
                            >
                              ชื่อ
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 rounded-s-lg bg-amber-50"
                            >
                              ราคา
                            </th>

                            <th scope="col" className="px-6 py-3 bg-red-50">
                              ตั้งค่า
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {specialadd.map((specialadd, index) => (
                            <>
                              <tr
                                key={index}
                                className="bg-white dark:bg-gray-800 text-center"
                              >
                                <th
                                  scope="row"
                                  className="px-6 py-4 bg-yellow-50 text-gray-900 whitespace-nowrap dark:text-white"
                                >
                                  {specialadd.namevalue}
                                </th>
                                <th
                                  scope="row"
                                  className="px-6 py-4 bg-yellow-50 text-gray-900 whitespace-nowrap dark:text-white"
                                >
                                  {specialadd.pricevalue}
                                </th>
                                <td className="px-6 py-4 bg-red-50 ">
                                  <div className="flex">
                                    <button
                                      onClick={(e) => remove(index)}
                                      className="btn btn-circle btn-outline btn-error w-2/4 h-2/4 mr-2"
                                    >
                                      <i className="fa-sharp fa-solid fa-trash"></i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </table>
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="stat col-span-6 sm:col-span-12 md:col-span-12 lg:col-span-12  xl:col-span-6 text-center">
                    <label className="input input-bordered input-accent flex items-center gap-2 w-full ">
                      ชื่อ
                      <input
                        type="text"
                        className="grow"
                        placeholder=""
                        onChange={(e) => setnamespecial(e.target.value)}
                        value={namespecial}
                      />
                    </label>
                  </div>
                  <div className="stat col-span-6 sm:col-span-12 md:col-span-12 lg:col-span-12  xl:col-span-6 text-center">
                    <label className="input input-bordered input-accent flex items-center gap-2 w-full ">
                      ชื่อรายการ
                      <input
                        type="text"
                        className="grow"
                        placeholder=""
                        onChange={(e) => setname(e.target.value)}
                        value={name}
                      />
                    </label>
                  </div>

                  <div className="stat col-span-6 sm:col-span-12 md:col-span-12 lg:col-span-12  xl:col-span-6 text-center">
                    <label className="input input-bordered input-accent flex items-center gap-2 w-full ">
                      ราคา
                      <input
                        type="text"
                        className="grow"
                        placeholder=""
                        onChange={(e) => setprice(e.target.value)}
                        value={price}
                      />
                    </label>
                  </div>

                  <div className="stat col-span-6 sm:col-span-12 md:col-span-12 lg:col-span-12  xl:col-span-6 text-center">
                    <button
                      onClick={adddata}
                      className="btn btn-square btn-outline bg-accent flex items-center gap-2 w-full "
                    >
                      เพิ่มสินค้า
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : button === "edit" ? (
            <>
              <div className="animate-fadeIn r">
                <div className="elative grid grid-cols-12 min-w-0 mt-5 mb-6 ml-5 break-words bg-gray-100 dark:bg-gray-950 shadow-soft-xl dark:shadow-soft-dark-xl rounded-2xl bg-clip-border col-span-12 sm:col-span-12 md:col-span-12 xl:col-span-12">
                  <div className="stats stats-vertical shadow col-span-12">
                    {specialadd ? (
                      <>
                        {" "}
                        <div className="ml-5 text-lg">
                          <button
                            onClick={editvaluesubmit}
                            className="btn btn-square btn-outline bg-red-50 col-span-9 flex items-center gap-2 w-full m-5 ml-10"
                          >
                            ยืนยันการแก้ไข : {namespecial}
                          </button>
                        </div>
                        <table className="w-full text-sm col-span-12 shadow rtl:text-right text-gray-500 dark:text-gray-400 ">
                          <thead className="text-center text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                              <th
                                scope="col"
                                className="px-6 py-3 rounded-s-lg bg-yellow-50"
                              >
                                ชื่อ
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 rounded-s-lg bg-amber-50"
                              >
                                ราคา
                              </th>

                              <th scope="col" className="px-6 py-3 bg-red-50">
                                ตั้งค่า
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {specialadd.map((specialadd, index) => (
                              <>
                                <tr
                                  key={index}
                                  className="bg-white dark:bg-gray-800 text-center"
                                >
                                  <th
                                    scope="row"
                                    className="px-6 py-4 bg-yellow-50 text-gray-900 whitespace-nowrap dark:text-white"
                                  >
                                    {specialadd.name}
                                  </th>
                                  <th
                                    scope="row"
                                    className="px-6 py-4 bg-yellow-50 text-gray-900 whitespace-nowrap dark:text-white"
                                  >
                                    {specialadd.price}
                                  </th>
                                  <td className="px-6 py-4 bg-red-50 ">
                                    <div className="flex">
                                      <button
                                        onClick={(e) =>
                                          handleRemoveItem(index, specialadd)
                                        }
                                        className="btn btn-circle btn-outline btn-error w-2/4 h-2/4 mr-2"
                                      >
                                        <i className="fa-sharp fa-solid fa-trash"></i>
                                      </button>

                                      <button
                                        onClick={(e) =>
                                          editvalue(specialadd, index)
                                        }
                                        className="btn btn-outline btn-info w-1/4 h-2/4 mr-2"
                                      >
                                        <i className="fa-sharp fa-solid fa-screwdriver-wrench"></i>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <></>
                    )}
                    <div className="stat col-span-6 sm:col-span-12 md:col-span-12 lg:col-span-12  xl:col-span-6 text-center">
                      <label className="input input-bordered input-accent flex items-center gap-2 w-full ">
                        ชื่อ
                        <input
                          type="text"
                          className="grow"
                          placeholder=""
                          onChange={(e) => setnamespecial(e.target.value)}
                          value={namespecial}
                        />
                      </label>
                    </div>
                    <div className="stat col-span-6 sm:col-span-12 md:col-span-12 lg:col-span-12  xl:col-span-6 text-center">
                      <label className="input input-bordered input-accent flex items-center gap-2 w-full ">
                        ชื่อรายการ
                        <input
                          type="text"
                          className="grow"
                          placeholder=""
                          onChange={(e) => setname(e.target.value)}
                          value={name}
                        />
                      </label>
                    </div>

                    <div className="stat col-span-6 sm:col-span-12 md:col-span-12 lg:col-span-12  xl:col-span-6 text-center">
                      <label className="input input-bordered input-accent flex items-center gap-2 w-full ">
                        ราคา
                        <input
                          type="text"
                          className="grow"
                          placeholder=""
                          onChange={(e) => setprice(e.target.value)}
                          value={price}
                        />
                      </label>
                    </div>

                    <div className="stat col-span-6 sm:col-span-12 md:col-span-12 lg:col-span-12  xl:col-span-6 text-center">
                      <button
                        onClick={(e) => editvaluearray()}
                        className="btn btn-square btn-outline bg-accent flex items-center gap-2 w-full "
                      >
                        แก้ไข
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : button === "product" ? (
            <>
              <>
                <div className="animate-fadeIn r">
                  <div className="elative grid grid-cols-12 min-w-0 mt-5 mb-6 ml-5 break-words bg-gray-100 dark:bg-gray-950 shadow-soft-xl dark:shadow-soft-dark-xl rounded-2xl bg-clip-border col-span-12 sm:col-span-12 md:col-span-12 xl:col-span-12">
                    <div className="stats stats-vertical shadow col-span-12">
                      {dataproductar ? (
                        <>
                          {" "}
                          <div className="ml-5 text-lg">
                            <button
                              onClick={adddataproductsubmit}
                              className="btn btn-square btn-outline bg-red-50 col-span-9 flex items-center gap-2 w-full m-5 ml-10"
                            >
                              ยืนยันการแก้ไข : {namespecial}
                            </button>
                          </div>
                          <table className="w-full text-sm col-span-12 shadow rtl:text-right text-gray-500 dark:text-gray-400 ">
                            <thead className="text-center text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                              <tr>
                                <th
                                  scope="col"
                                  className="px-6 py-3 rounded-s-lg bg-yellow-50"
                                >
                                  ชื่อสินค้า
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 rounded-s-lg bg-amber-50"
                                >
                                  ราคา
                                </th>

                                <th scope="col" className="px-6 py-3 bg-red-50">
                                  ตั้งค่า
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {dataproductar.map((dataproductar, index) => (
                                <>
                                  {dataproduct.map((dataproduct) => (
                                    <>
                                      <>
                                        {dataproductar.Productid ==
                                        dataproduct.id ? (
                                          <>
                                            <tr
                                              key={index}
                                              className="bg-white dark:bg-gray-800 text-center"
                                            >
                                              <th
                                                scope="row"
                                                className="px-6 py-4 bg-yellow-50 text-gray-900 whitespace-nowrap dark:text-white"
                                              >
                                                {dataproduct.Productname}
                                              </th>
                                              <th
                                                scope="row"
                                                className="px-6 py-4 bg-yellow-50 text-gray-900 whitespace-nowrap dark:text-white"
                                              >
                                                {dataproduct.Producprice}
                                              </th>
                                              <td className="px-6 py-4 bg-red-50 ">
                                                <div className="flex">
                                                  <button
                                                    onClick={(e) =>
                                                      delPro(
                                                        index,
                                                        dataproductar
                                                      )
                                                    }
                                                    className="btn btn-circle btn-outline btn-error w-2/4 h-2/4 mr-2"
                                                  >
                                                    <i className="fa-sharp fa-solid fa-trash"></i>
                                                  </button>
                                                </div>
                                              </td>{" "}
                                            </tr>
                                          </>
                                        ) : dataproductar.id ==
                                          dataproduct.id ? (
                                          <>
                                            <tr
                                              key={index}
                                              className="bg-white dark:bg-gray-800 text-center"
                                            >
                                              <th
                                                scope="row"
                                                className="px-6 py-4 bg-yellow-50 text-gray-900 whitespace-nowrap dark:text-white"
                                              >
                                                {dataproduct.Productname}
                                              </th>
                                              <th
                                                scope="row"
                                                className="px-6 py-4 bg-yellow-50 text-gray-900 whitespace-nowrap dark:text-white"
                                              >
                                                {dataproduct.Producprice}
                                              </th>
                                              <td className="px-6 py-4 bg-red-50 ">
                                                <div className="flex">
                                                  <button
                                                    onClick={(e) =>
                                                      delPro(
                                                        index,
                                                        dataproductar
                                                      )
                                                    }
                                                    className="btn btn-circle btn-outline btn-error w-2/4 h-2/4 mr-2"
                                                  >
                                                    <i className="fa-sharp fa-solid fa-trash"></i>
                                                  </button>
                                                </div>
                                              </td>{" "}
                                            </tr>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                      {/* ))}  */}
                                    </>
                                  ))}{" "}
                                  {dataproductar.id == dataproduct.id ? (
                                    <></>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ))}
                            </tbody>
                          </table>
                        </>
                      ) : (
                        <></>
                      )}
                      <div className="stat col-span-6 sm:col-span-12 md:col-span-12 lg:col-span-12  xl:col-span-6 text-center">
                        หมวดหมู่
                        <select
                          onChange={(e) => setcategoryid(e.target.value)}
                          value={categoryid}
                          className="select select-accent flex items-center gap-2 w-full "
                        >
                          <option value="0">เลือกหมวดหมู่สินค้า</option>
                          {category.length > 0 ? (
                            <>
                              {category.map((item, index) => (
                                <>
                                  {" "}
                                  <option key={index} value={item.id}>
                                    {item.Categoryname}
                                  </option>
                                </>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                      </div>

                      <div className="stat col-span-6 sm:col-span-12 md:col-span-12 lg:col-span-12  xl:col-span-6 text-center">
                        สินค้า
                        <select
                          onChange={(e) => setdataproductid(e.target.value)}
                          value={dataproductid}
                          className="select select-accent flex items-center gap-2 w-full "
                        >
                          <option value="0">เลือกหมวดหมู่สินค้า</option>
                          {dataproduct.length > 0 ? (
                            <>
                              {dataproduct.map((item, index) => (
                                <>
                                  {item.Categoryid == categoryid ? (
                                    <>
                                      <option key={index} value={item.id}>
                                        {item.Productname}
                                      </option>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                      </div>
                      <div className="stat col-span-6 sm:col-span-12 md:col-span-12 lg:col-span-12  xl:col-span-6 text-center">
                        <button
                          onClick={(e) => adddataproduct()}
                          className="btn btn-square btn-outline bg-accent flex items-center gap-2 w-full "
                        >
                          เพิ่มสินค้า
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </>
          ) : (
            <></>
          )}

          <div className="relative flex flex-col min-w-0 mt-5 mb-6 ml-5 break-words  dark:bg-gray-950 shadow-soft-xl dark:shadow-soft-dark-xl rounded-2xl bg-clip-border border-spacing-6 col-span-12">
            <div className="ml-1 col-span-10 ">
              {" "}
              <i className="fa-sharp fa-solid fa-chair mr-2"></i>
              รายการทั้งหมด
              <button
                onClick={add}
                className="btn btn-square btn-outline btn-accent col-span-1 w-1/6 h-auto m-5"
              >
                <i className="fa-sharp fa-solid fa-plus"></i>
              </button>
            </div>
            <div className="relative overflow-x-auto shadow-soft-xl dark:shadow-soft-dark-xl rounded-2xl bg-clip-border border-spacing-6">
              <table className="w-full text-sm text-left shadow rtl:text-right text-gray-500 dark:text-gray-400 ">
                <thead className="text-center text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3 rounded-s-lg bg-yellow-50">
                      ชื่อ
                    </th>
                    <th scope="col" className="px-6 py-3 rounded-s-lg bg-red-50">
                      รายการ
                    </th>
                    <th scope="col" className="px-6 py-3 rounded-s-lg bg-amber-50">
                      สินค้า
                    </th>
                    <th scope="col" className="px-6 py-3 bg-red-50">
                      ตั้งค่า
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {special ? (
                    <>
                      {special.map((special, index) => (
                        <>
                          <tr
                            key={index}
                            className="bg-white dark:bg-gray-800 text-center"
                          >
                            <td
                              scope="row"
                              className="px-6 py-4 bg-yellow-50 text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {special.name}
                            </td>
                            <td
                              scope="row"
                              className=" bg-red-50 text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {special.ValueSetProduct.map(
                                (ValueSetProduct) => (
                                  <>
                                    {/* <td
                                      scope="row"
                                      className="px-4 bg-red-50 text-gray-900 whitespace-nowrap dark:text-white"
                                    > */}
                                    <div className="m-2">
                                      {ValueSetProduct.name} :
                                      {ValueSetProduct.price} ฿
                                    </div>

                                    {/* </td> */}
                                  </>
                                )
                              )}
                            </td>
                            <td
                              scope="row"
                              className=" bg-yellow-50 text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {special.special.map((special, index) => (
                                <div className="m-2">
                                  {special.Product.Productname}
                                </div>
                              ))}
                            </td>
                            <td className="px-6 py-4 bg-red-50 ">
                              <div className="flex">
                                <button
                                  onClick={(e) => del(special)}
                                  className="btn btn-circle btn-outline btn-error w-1/4 h-2/4 ml-3 mr-2"
                                >
                                  <i className="fa-sharp fa-solid fa-trash"></i>
                                </button>

                                <button
                                  onClick={(e) => edit(special)}
                                  className="btn btn-outline btn-info w-1/4 h-2/4 mr-2"
                                >
                                  <i className="fa-sharp fa-solid fa-screwdriver-wrench"></i>
                                </button>
                                <button
                                  onClick={(e) => product(special)}
                                  className="btn btn-outline btn-accent w-1/4 h-2/4 mr-2"
                                >
                                  <i className="fa-sharp fa-solid fa-plus"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        </>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}

                  {/* <tr key={index} className="bg-white dark:bg-gray-800 text-center">
                    <th
                      scope="row"
                      className="px-6 py-4 bg-yellow-50 text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      
                    </th>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Dashboard>
    </>
  );
}
