import { useEffect, useState } from "react";
import axios from "axios";
import BarEmployee from "./component/BarEmployee";

import config from "../config";
export default function DashboardTotalEM() {
  const [totalPrice , settotalPrice] = useState('');
  const [totalPricemoney , settotalPricemoney] = useState('');
  const [paymentpromptpay , setpaymentpromptpay] = useState('');
  const [counter , setcounter] = useState('');
  const [category , setcategory] = useState('');
  const [product , setproduct] = useState('');
  const [BillsaleDetail , setBillsaleDetail] = useState('');
  useEffect(() => {
    total();
  }, []);
  const total = async() => {
    try {
      const res = await axios.get(config.apipath + 'owner/total');
      console.log(res.data.totalPrice)
      settotalPrice(res.data.totalPrice);
      settotalPricemoney(res.data.totalPricemoney);
      setpaymentpromptpay(res.data.paymentpromptpay);
      setcounter(res.data.counter);
      setcategory(res.data.category);
      setproduct(res.data.product)
      setBillsaleDetail(res.data.BillsaleDetail);
    } catch (error) {
      
    }
  }
  return (
    <>
      <BarEmployee>
        <div className="mt-5 col-span-12 m-2 rounded-lg sm:grid-cols-8">
          <div className="flex flex-wrap mb-10">
            <div className="w-1/2 xl:w-1/4 px-3">
              <div className="w-full bg-white border text-blue-400 rounded-lg flex items-center p-6 mb-6 xl:mb-0">
                <svg
                  className="w-16 h-16 fill-current mr-4 hidden lg:block"
                  viewBox="0 0 20 20"
                >
                  <path d="M17.35,2.219h-5.934c-0.115,0-0.225,0.045-0.307,0.128l-8.762,8.762c-0.171,0.168-0.171,0.443,0,0.611l5.933,5.934c0.167,0.171,0.443,0.169,0.612,0l8.762-8.763c0.083-0.083,0.128-0.192,0.128-0.307V2.651C17.781,2.414,17.587,2.219,17.35,2.219M16.916,8.405l-8.332,8.332l-5.321-5.321l8.333-8.332h5.32V8.405z M13.891,4.367c-0.957,0-1.729,0.772-1.729,1.729c0,0.957,0.771,1.729,1.729,1.729s1.729-0.772,1.729-1.729C15.619,5.14,14.848,4.367,13.891,4.367 M14.502,6.708c-0.326,0.326-0.896,0.326-1.223,0c-0.338-0.342-0.338-0.882,0-1.224c0.342-0.337,0.881-0.337,1.223,0C14.84,5.826,14.84,6.366,14.502,6.708"></path>
                </svg>

                <div className="text-gray-700">
                  <p className="font-semibold text-3xl">{counter}</p>
                  <p>เคาน์เตอร์</p>
                </div>
              </div>
            </div>

            <div className="w-1/2 xl:w-1/4 px-3">
              <div className="w-full bg-white border text-blue-400 rounded-lg flex items-center p-6 mb-6 xl:mb-0">
                <svg
                  className="w-16 h-16 fill-current mr-4 hidden lg:block"
                  viewBox="0 0 20 20"
                >
                  <path d="M17.684,7.925l-5.131-0.67L10.329,2.57c-0.131-0.275-0.527-0.275-0.658,0L7.447,7.255l-5.131,0.67C2.014,7.964,1.892,8.333,2.113,8.54l3.76,3.568L4.924,17.21c-0.056,0.297,0.261,0.525,0.533,0.379L10,15.109l4.543,2.479c0.273,0.153,0.587-0.089,0.533-0.379l-0.949-5.103l3.76-3.568C18.108,8.333,17.986,7.964,17.684,7.925 M13.481,11.723c-0.089,0.083-0.129,0.205-0.105,0.324l0.848,4.547l-4.047-2.208c-0.055-0.03-0.116-0.045-0.176-0.045s-0.122,0.015-0.176,0.045l-4.047,2.208l0.847-4.547c0.023-0.119-0.016-0.241-0.105-0.324L3.162,8.54L7.74,7.941c0.124-0.016,0.229-0.093,0.282-0.203L10,3.568l1.978,4.17c0.053,0.11,0.158,0.187,0.282,0.203l4.578,0.598L13.481,11.723z"></path>
                </svg>

                <div className="text-gray-700">
                  <p className="font-semibold text-3xl">{category}</p>
                  <p>หมวดหมู่ ทั้งหมด</p>
                </div>
              </div>
            </div>

            <div className="w-1/2 xl:w-1/4 px-3">
              <div className="w-full bg-white border text-blue-400 rounded-lg flex items-center p-6">
                <svg
                  className="w-16 h-16 fill-current mr-4 hidden lg:block"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 4h1.5L9 16m0 0h8m-8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-8.5-3h9.25L19 7H7.312"
                  />
                </svg>

                <div className="text-gray-700">
                  <p className="font-semibold text-3xl">{product}</p>
                  <p>สินค้าทั้งหมด</p>
                </div>
              </div>
            </div>

            <div className="w-1/2 xl:w-1/4 px-3">
              <div className="w-full bg-white border text-blue-400 rounded-lg flex items-center p-6">
                <svg
                  className="w-16 h-16 fill-current mr-4 hidden lg:block"
                  viewBox="0 0 20 20"
                >
                  <path d="M17.431,2.156h-3.715c-0.228,0-0.413,0.186-0.413,0.413v6.973h-2.89V6.687c0-0.229-0.186-0.413-0.413-0.413H6.285c-0.228,0-0.413,0.184-0.413,0.413v6.388H2.569c-0.227,0-0.413,0.187-0.413,0.413v3.942c0,0.228,0.186,0.413,0.413,0.413h14.862c0.228,0,0.413-0.186,0.413-0.413V2.569C17.844,2.342,17.658,2.156,17.431,2.156 M5.872,17.019h-2.89v-3.117h2.89V17.019zM9.587,17.019h-2.89V7.1h2.89V17.019z M13.303,17.019h-2.89v-6.651h2.89V17.019z M17.019,17.019h-2.891V2.982h2.891V17.019z"></path>
                </svg>

                <div className="text-gray-700">
                  <p className="font-semibold text-3xl">{BillsaleDetail}</p>
                  <p>ยอดสินค้าที่ขายไปแล้ว</p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap mb-10">
            <div className="w-1/2 xl:w-1/4 px-3">
              <div className="w-full bg-white border text-blue-400 rounded-lg flex items-center p-6 mb-6 xl:mb-0">
                <svg
                  className="w-16 h-16 fill-current mr-4 hidden lg:block"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 7.205c4.418 0 8-1.165 8-2.602C20 3.165 16.418 2 12 2S4 3.165 4 4.603c0 1.437 3.582 2.602 8 2.602ZM12 22c4.963 0 8-1.686 8-2.603v-4.404c-.052.032-.112.06-.165.09a7.75 7.75 0 0 1-.745.387c-.193.088-.394.173-.6.253-.063.024-.124.05-.189.073a18.934 18.934 0 0 1-6.3.998c-2.135.027-4.26-.31-6.3-.998-.065-.024-.126-.05-.189-.073a10.143 10.143 0 0 1-.852-.373 7.75 7.75 0 0 1-.493-.267c-.053-.03-.113-.058-.165-.09v4.404C4 20.315 7.037 22 12 22Zm7.09-13.928a9.91 9.91 0 0 1-.6.253c-.063.025-.124.05-.189.074a18.935 18.935 0 0 1-6.3.998c-2.135.027-4.26-.31-6.3-.998-.065-.024-.126-.05-.189-.074a10.163 10.163 0 0 1-.852-.372 7.816 7.816 0 0 1-.493-.268c-.055-.03-.115-.058-.167-.09V12c0 .917 3.037 2.603 8 2.603s8-1.686 8-2.603V7.596c-.052.031-.112.059-.165.09a7.816 7.816 0 0 1-.745.386Z" />
                </svg>

                <div className="text-gray-700">
                  <p className="font-semibold text-3xl">{paymentpromptpay}</p>
                  <p>ยอดเงินโอน</p>
                </div>
              </div>
            </div>

            <div className="w-1/2 xl:w-1/4 px-3">
              <div className="w-full bg-white border text-blue-400 rounded-lg flex items-center p-6 mb-6 xl:mb-0">
                <svg
                  className="w-16 h-16 fill-current mr-4 hidden lg:block"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7 6a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2h-2v-4a3 3 0 0 0-3-3H7V6Z"
                    clip-rule="evenodd"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M2 11a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-7Zm7.5 1a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Z"
                    clip-rule="evenodd"
                  />
                  <path d="M10.5 14.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z" />
                </svg>

                <div className="text-gray-700">
                  <p className="font-semibold text-3xl">{totalPricemoney}</p>
                  <p>ยอดเงินสด</p>
                </div>
              </div>
            </div>

            <div className="w-1/2 xl:w-1/4 px-3">
              <div className="w-full bg-white border text-blue-400 rounded-lg flex items-center p-6">
                <svg
                  className="w-16 h-16 fill-current mr-4 hidden lg:block"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M9 15a6 6 0 1 1 12 0 6 6 0 0 1-12 0Zm3.845-1.855a2.4 2.4 0 0 1 1.2-1.226 1 1 0 0 1 1.992-.026c.426.15.809.408 1.111.749a1 1 0 1 1-1.496 1.327.682.682 0 0 0-.36-.213.997.997 0 0 1-.113-.032.4.4 0 0 0-.394.074.93.93 0 0 0 .455.254 2.914 2.914 0 0 1 1.504.9c.373.433.669 1.092.464 1.823a.996.996 0 0 1-.046.129c-.226.519-.627.94-1.132 1.192a1 1 0 0 1-1.956.093 2.68 2.68 0 0 1-1.227-.798 1 1 0 1 1 1.506-1.315.682.682 0 0 0 .363.216c.038.009.075.02.111.032a.4.4 0 0 0 .395-.074.93.93 0 0 0-.455-.254 2.91 2.91 0 0 1-1.503-.9c-.375-.433-.666-1.089-.466-1.817a.994.994 0 0 1 .047-.134Zm1.884.573.003.008c-.003-.005-.003-.008-.003-.008Zm.55 2.613s-.002-.002-.003-.007a.032.032 0 0 1 .003.007ZM4 14a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0v-4a1 1 0 0 1 1-1Zm3-2a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1Zm6.5-8a1 1 0 0 1 1-1H18a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0v-.796l-2.341 2.049a1 1 0 0 1-1.24.06l-2.894-2.066L6.614 9.29a1 1 0 1 1-1.228-1.578l4.5-3.5a1 1 0 0 1 1.195-.025l2.856 2.04L15.34 5h-.84a1 1 0 0 1-1-1Z"
                    clip-rule="evenodd"
                  />
                </svg>

                <div className="text-gray-700">
                  <p className="font-semibold text-3xl">{totalPrice}</p>
                  <p>รวมยอดเงินทั้งหมด</p>
                </div>
              </div>
            </div>

            {/* <div className="w-1/2 xl:w-1/4 px-3">
              <div className="w-full bg-white border text-blue-400 rounded-lg flex items-center p-6">
                <svg
                  className="w-16 h-16 fill-current mr-4 hidden lg:block"
                  viewBox="0 0 20 20"
                >
                  <path d="M17.431,2.156h-3.715c-0.228,0-0.413,0.186-0.413,0.413v6.973h-2.89V6.687c0-0.229-0.186-0.413-0.413-0.413H6.285c-0.228,0-0.413,0.184-0.413,0.413v6.388H2.569c-0.227,0-0.413,0.187-0.413,0.413v3.942c0,0.228,0.186,0.413,0.413,0.413h14.862c0.228,0,0.413-0.186,0.413-0.413V2.569C17.844,2.342,17.658,2.156,17.431,2.156 M5.872,17.019h-2.89v-3.117h2.89V17.019zM9.587,17.019h-2.89V7.1h2.89V17.019z M13.303,17.019h-2.89v-6.651h2.89V17.019z M17.019,17.019h-2.891V2.982h2.891V17.019z"></path>
                </svg>

                <div className="text-gray-700">
                  <p className="font-semibold text-3xl">53</p>
                  <p>ยอดขายทั้งหมด</p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </BarEmployee>
    </>
  );
}
